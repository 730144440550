import styled from 'styled-components';

export const Base = styled.div`
    display: flex;
    justify-content: center;
    width: 100vw; 
`;

export const Conteudo = styled.div`
    width: 50vw;
    display: grid;
    justify-content: center;
    border-left: 1px solid #ffffff;
    border-right: 1px solid #ffffff;

    @media (max-width: 1000px) {
        width: 60%;
    }

    @media (max-width: 900px) {
        width: 65%;
    }

    @media (max-width: 800px) {
        width: 70%;
    }

    @media (max-width: 700px) {
        width: 75%;
    }

    @media (max-width: 600px) {
        width: 80%;
    }

    @media (max-width: 500px) {
        width: 85%;
    }

    @media (max-width: 400px) {
        width: 90%;
    }
`;

export const DivEncaixe = styled.div`
    display: grid !important;
    min-width: 50vw;
    display: flex;
    justify-content: center;
`;

export const DivInputs = styled.div`
    width: 40vw;
    display: grid;
    justify-content: center;
    margin-top: 30px;
    gap: 20px;

    @media (max-width: 1000px) {
        width: 50vw;
    }

    @media (max-width: 900px) {
        width: 55vw;
    }

    @media (max-width: 800px) {
        width: 60vw;
    }

    @media (max-width: 700px) {
        width: 65vw;
    }

    @media (max-width: 600px) {
        width: 70vw;
    }

    @media (max-width: 500px) {
        width: 75vw;
    }

    @media (max-width: 400px) {
        width: 80vw;
    }
`;

export const DivButtons = styled.div`
    display: flex;
    height: 60px;
    justify-content: space-between;
    border: 5px solid #80010E;
    border-radius: 50px;
    gap: 40px;
    margin-top: 30px;
    cursor: pointer;
    background-color: #80010E;

    @media (max-width: 500px) {
        gap: 20px;
    }
`;

export const DivReturn = styled.div`
    display: flex;
    justify-content: start;
    margin-left: 10px;
    align-items: center;
    color: #ffffff;
    width: 30%;
    gap: 10px;
    cursor: pointer;

    @media (max-width: 500px) {
        gap: 0px;
        width: 10%;
        margin-left: 4px;
    }
`;

export const DivProximo = styled.div`
    background-color: #ffffff;
    color: #CE0F2A;
    border-radius: 50px;
    font-size: 20px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 70%;
    cursor: pointer;

    @media (max-width: 800px) {
        font-size: 15px;
    }

    @media (max-width: 800px) {
        width: 90%;
    }

    @media (max-width: 800px) {
        width: 90%;
    }

    @media (max-width: 350px) {
        padding-left: 20px;
    }
`;

export const DivFlex = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const DivButton = styled.div`
    height: 60px;
    border-radius: 50px;
    border: 1px solid #ffffff;
    color: ${({ active }) => { if (active === true) { return '#CE0F2A'; } else { return '#ffffff'; }}};
    align-items: center;
    padding: 0 30px;
    display: flex;
    background-color: ${({ active }) => { if (active === true) { return '#ffffff'; } else { return '#CE0F2A'; }}};
    cursor: pointer;

    @media (max-width: 700px) {
        font-size: 14px;
    }

    @media (max-width: 500px) {
        height: 90px;
    }

    @media (max-width: 350px) {
        height: 140px;
    }
`;

export const TextButton = styled.text`
    font-size: 15px;
    color: #ffffff;
`;

export const DivTextBody = styled.div`
    display: grid;
    justify-content: center;
    text-align: center;
    width: 40vw:

    @media (max-width: 1000px) {
        width: 50vw;
    }

    @media (max-width: 900px) {
        width: 55vw;
    }

    @media (max-width: 800px) {
        width: 60vw;
    }

    @media (max-width: 700px) {
        width: 65vw;
    }

    @media (max-width: 600px) {
        width: 70vw;
    }

    @media (max-width: 500px) {
        width: 75vw;
    }

    @media (max-width: 400px) {
        width: 80vw;
    }
`;

export const DivTextBody2 = styled.div`
    display: flex;
    justify-content: center;
    text-align: center;
    border-radius: 30px;
    background-color: #FFEF09;
    width: 100%;
    height: 60px;
    gap: 20px;
`;

export const TextBody = styled.text`
    font-size: 25px;
    font-weight: bold;
    color: #ffffff;
`;

export const TextBody2 = styled.span`
    font-size: 16px;
    font-weight: bold;
    color: #CE0F2A;
    display: flex;
    align-items: center;
`;

export const TextNone = styled.div`
    font-weight: bold;
    @media (max-width: 500px) {
        display: none;
    }
`;
