import styled from 'styled-components';
import { Input } from 'reactstrap';

export const CustomModal = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10001;
    width: 100vw;
    height: 100vh;
    background-color: #CE0F2AF0;
    display: flex;
    justify-content: center;
    padding-top: 20%;
`;

export const DivModal = styled.div`
    width: 770px;
    height: 350px;
    margin-top: -100px;

    @media (max-width: 800px) {
        width: 650px;
    }

    @media (max-width: 700px) {
        width: 550px;
        margin-top: 0px;
    }

    @media (max-width: 600px) {
        width: 450px;
    }

    @media (max-width: 500px) {
        width: 350px;
    }

    @media (max-width: 400px) {
        width: 250px;
    }
`;

export const ModalBody = styled.div`
    width: 770px;
    height: 350px;
    background-color: #fff;
    border-radius: 20px;
    margin-top: 10px;
    text-align: center;
    margin-top: -20px;
    display: grid;
    justify-content: center;
    align-items: center;

    @media (max-width: 800px) {
        width: 650px;
    }

    @media (max-width: 700px) {
        width: 550px;
    }

    @media (max-width: 600px) {
        width: 450px;
    }

    @media (max-width: 500px) {
        width: 350px;
    }

    @media (max-width: 400px) {
        width: 250px;
    }
`;

export const DivProximo = styled.div`
    height: 60px;
    background-color: #ffffff;
    color: #CE0F2A;
    border: 5px solid #CE0F2A;
    border-radius: 50px;
    font-size: 20px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 650px;
    cursor: pointer;

    @media (max-width: 800px) {
        width: 600px;
    }

    @media (max-width: 700px) {
        width: 500px;
    }

    @media (max-width: 600px) {
        width: 400px;
    }

    @media (max-width: 500px) {
        width: 300px;
    }

    @media (max-width: 400px) {
        width: 200px;
    }
`;

export const Body = styled.div`
    width: 650px;
    gap: 30px;
    height: 250px;
    display: grid;
    justify-content: center;

    @media (max-width: 700px) {
        width: 550px;
    }

    @media (max-width: 600px) {
        width: 450px;
    }

    @media (max-width: 500px) {
        width: 350px;
    }

    @media (max-width: 400px) {
        width: 250px;
    }
`;

export const HeaderModal = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const Left = styled.div`
    font-size: 18px;
    font-weight: 600;
    color: #ffffff;
`;

export const Right = styled.div`
    cursor: pointer;
    border: 1px solid #ffffff;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: -10px;
    background-color: #CE0F2A;
    margin-top: 50px;

    @media (max-width: 500px) {
        margin-top: 100px;
    }
`;

export const CustomInput = styled(Input)`
    padding: 0.5em;
    margin: 0.5em;
    background: var(--primary);
    color: #CE0F2A;
    border-style: solid;
    border-bottom-width: 1px #CE0F2A;
    border-top-width: 0;
    border-right-width: 0;
    border-left-width: 0;
    width: 100%;

    &:focus {
        box-shadow: 0 0 0 0;
        outline: 0;
        background: #ffffff;
        color: #CE0F2A;
        border: 1px solid var #CE0F2A;
    }
    
    &::-webkit-input-placeholder {
        color: #CE0F2A;
    }

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      -webkit-transition: "color 9999s ease-out, background-color 9999s ease-out";
      -webkit-transition-delay: 9999s;
    }
`;

export const DivInput = styled.label`
    width: 100%;
    height: 25px;
    text-align: center;
    border-bottom: 1px solid #CE0F2A;
    margin-top: 20px;
    cursor: pointer;
`;

export const TextInput = styled.p`
    color: #CE0F2A;
    font-size: 14px;
    margin-left: 10px;
`;

export const CustomInput2 = styled(Input)`
    display: none;
`;
