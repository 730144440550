import React from 'react';
import * as S from './styled';
import { FormGroup, Label } from 'reactstrap';

const Pagina2 = ({ 
    handleClick, 
    nome, setNome, 
    nomeSocial, setNomeSocial, 
    celular, setCelular, 
    email, setEmail, 
    genero, setGenero, 
    raca, setRaca, 
    cargo, setCargo, 
    doenca, setDoenca,
    afastamento, setAfastamento,
    office, setOffice,
    acompanhada, clique, setClique
 }) => {

  return (
    <>
      <S.DivX>
        <S.Not onClick={() => setClique(!clique)}>Não quero mais me identificar</S.Not>
        <S.X onClick={() => setClique(!clique)}>X</S.X>
      </S.DivX>
      <S.Base>
        <S.Conteudo>
          <S.DivTitle>
            <S.TextBody>Caso queira acompanhar e receber os andamentos da sua denúncia, você precisa se identificar</S.TextBody>
          </S.DivTitle>
          <S.TextLight>Nome</S.TextLight>
          <S.CustomInput
            id="nome"
            type="text"
            name="nome"
            onChange={(e) => setNome(e.target.value)}
            value={nome}
          />
          <S.TextLight>Nome Social</S.TextLight>
          <S.CustomInput
            id="nomeSocial"
            type="text"
            name="nomeSocial"
            onChange={(e) => setNomeSocial(e.target.value)}
            value={nomeSocial}
          />
          <S.TextLight>Celular</S.TextLight>
          <S.CustomInput2
            id="celular"
            type="text"
            name="celular"
            onChange={(e) => setCelular(e.target.value)}
            value={celular}
            mask="(99) 99999-9999"
          />
          <S.TextLight>E-mail</S.TextLight>
          <S.CustomInput
            id="email"
            type="text"
            name="email"
            onChange={(e) => setEmail(e.target.value)}
            value={email}
          />
          <S.TextLight>Gênero</S.TextLight>
          <S.CustomInput type="select" name="genero" required value={genero} onChange={(e) => setGenero(e.target.value)}>
              <option value="DEFAULT"> -- Selecione um gênero -- </option>
              <option value="feminino">Feminino</option>
              <option value="masculino">Masculino</option>
              <option value="intersexo">Intersexo</option>
              <option value="naoBinario">Não binário</option>
              <option value="outros">Outros</option>
              <option value="naoInformado">Não informado</option>
          </S.CustomInput>
          <S.TextLight>Raça</S.TextLight>
          <S.CustomInput type="select" name="raca" required value={raca} onChange={(e) => setRaca(e.target.value)}>
              <option value="DEFAULT"> -- Selecione uma raça -- </option>
              <option value="branca">Branca</option>
              <option value="preta">Preta</option>
              <option value="amarela">Amarela</option>
              <option value="indigena">Indígena</option>
              <option value="naoInformado">Não informado</option>
          </S.CustomInput>
          <S.TextLight>Cargo</S.TextLight>
          <S.CustomInput type="select" name="cargo" required value={cargo} onChange={(e) => setCargo(e.target.value)}>
              <option value="DEFAULT"> -- Selecione um cargo -- </option>
              <option value="estagiario">Estagiário</option>
              <option value="caixa">Caixa</option>
              <option value="comercial">Atendente comercial</option>
              <option value="agenteComercial">Agente comercial</option>
              <option value="assistenteGerencia">Assistente da gerência</option>
              <option value="gerente">Gerente</option>
              <option value="outro">Outro</option>
          </S.CustomInput>
          <S.TextLight>Tem doença relacionada ao trabalho?</S.TextLight>
          <S.DivFlex>
            <FormGroup check>
              <Label check style={{ color: "#ffffff", display: "flex" }}>
                <S.CustomInputRadio type="radio" name="doenca" value="doencaSim" checked={doenca === "doencaSim"} onChange={(e) => setDoenca(e.target.value)} style={{ backgroundColor: "#CE0F2A", border: "1px solid #ffffff" }} />
                <S.CustomLabel>
                  Sim
                </S.CustomLabel>
              </Label>
            </FormGroup>
            <FormGroup check style={{ marginLeft: "10px" }}>
              <Label check style={{ color: "#ffffff", display: "flex" }}>
                <S.CustomInputRadio type="radio" name="doenca" value="doencaNao" checked={doenca === "doencaNao"} onChange={(e) => setDoenca(e.target.value)} style={{ backgroundColor: "#CE0F2A", border: "1px solid #ffffff" }} />
                <S.CustomLabel>
                  Não
                </S.CustomLabel>
              </Label>
            </FormGroup>
          </S.DivFlex>
          <S.TextLight>Tem histórico de afastamento?</S.TextLight>
          <S.DivFlex>
            <FormGroup check>
              <Label check style={{ color: "#ffffff", display: "flex" }}>
                <S.CustomInputRadio type="radio" name="afastamento" value="afastamentoSim" checked={afastamento === "afastamentoSim"} onChange={(e) => setAfastamento(e.target.value)} style={{ backgroundColor: "#CE0F2A", border: "1px solid #ffffff" }} />
                <S.CustomLabel>
                  Sim
                </S.CustomLabel>
              </Label>
            </FormGroup>
            <FormGroup check style={{ marginLeft: "10px" }}>
              <Label check style={{ color: "#ffffff", display: "flex" }}>
                <S.CustomInputRadio type="radio" name="afastamento" value="afastamentoNao" checked={afastamento === "afastamentoNao"} onChange={(e) => setAfastamento(e.target.value)} style={{ backgroundColor: "#CE0F2A", border: "1px solid #ffffff" }} />
                <S.CustomLabel>
                  Não
                </S.CustomLabel>
              </Label>
            </FormGroup>
          </S.DivFlex>
          <S.TextLight>Home office?</S.TextLight>
          <S.DivFlex2>
            <FormGroup check>
              <Label check style={{ color: "#ffffff", display: "flex" }}>
                <S.CustomInputRadio type="radio" name="office" value="officeSim" checked={office === "officeSim"} onChange={(e) => setOffice(e.target.value)} style={{ backgroundColor: "#CE0F2A", border: "1px solid #ffffff" }} />
                <S.CustomLabel>
                  Sim
                </S.CustomLabel>
              </Label>
            </FormGroup>
            <FormGroup check style={{ marginLeft: "10px" }}>
              <Label check style={{ color: "#ffffff", display: "flex" }}>
                <S.CustomInputRadio type="radio" name="office" value="officeNao" checked={office === "officeNao"} onChange={(e) => setOffice(e.target.value)} style={{ backgroundColor: "#CE0F2A", border: "1px solid #ffffff" }} />
                <S.CustomLabel>
                  Não
                </S.CustomLabel>
              </Label>
            </FormGroup>
            <FormGroup check style={{ marginLeft: "10px" }}>
              <Label check style={{ color: "#ffffff", display: "flex" }}>
                <S.CustomInputRadio type="radio" name="office" value="officeParcial" checked={office === "officeParcial"} onChange={(e) => setOffice(e.target.value)} style={{ backgroundColor: "#CE0F2A", border: "1px solid #ffffff" }} />
                <S.CustomLabel>
                  Híbrido
                </S.CustomLabel>
              </Label>
            </FormGroup>
          </S.DivFlex2>
          {/* <S.DivButtons>
            <S.DivReturn onClick={() => handleClick(1)}><img src={Return} alt="Retornar" /><S.TextNone>Retornar</S.TextNone></S.DivReturn>
            <S.DivProximo onClick={() => handleClick(3)}>Ir para o próximo passo</S.DivProximo>
          </S.DivButtons> */}
        </S.Conteudo>
      </S.Base>
    </>
  );
};

export default Pagina2;