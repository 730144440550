import React from 'react';

function Nuvem(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="25.003" height="17.002" viewBox="0 0 25.003 17.002" fill="none" {...props}>
            <g id="Grupo_2305" data-name="Grupo 2305" transform="translate(-212.998 -350.998)">
                <path id="União_14" data-name="União 14" d="M-13523.957,19750.369a28.477,28.477,0,0,1-5.881.631,8.34,8.34,0,0,1-8.164-8.5,8.34,8.34,0,0,1,8.164-8.5c2.138,0,5.1,1.422,7.626,3.418a12.772,12.772,0,0,1,2.536-.324,6.819,6.819,0,0,1,6.677,6.953,6.82,6.82,0,0,1-6.677,6.955A19.828,19.828,0,0,1-13523.957,19750.369Z" transform="translate(13751 -19383)" fill="#9e9b9b"/>
                <path id="União_15" data-name="União 15" d="M-13536.256,19741v-3.5h3.5v3.5Zm-1.74-3.5,3.494-3.5,3.5,3.5Z" transform="translate(13759.996 -19378)" fill="#fcfdfd"/>
            </g>
        </svg>
    )
}

export default Nuvem;