import React, { useState, useEffect } from 'react';
import * as S from './styled';
import X from '../../assets/imagens/X';
import AddArquivo from '../../assets/imagens/addArquivo.png';
import Nuvem from '../../assets/imagens/Nuvem';

const ModalFull = ({ 
        handleClick, modal, setModal, 
        nomeArquivo, setNomeArquivo,
        nomeArquivo2, setNomeArquivo2,
        nomeArquivo3, setNomeArquivo3,
        nomeArquivo4, setNomeArquivo4,
        nomeArquivo5, setNomeArquivo5,
        nomeArquivo6, setNomeArquivo6,
        nomeArquivo7, setNomeArquivo7,
        nomeArquivo8, setNomeArquivo8,
        nomeArquivo9, setNomeArquivo9,
        nomeArquivo10, setNomeArquivo10,
        picture, setPicture, imgData, setImgData,
        picture2, setPicture2, imgData2, setImgData2,
        picture3, setPicture3, imgData3, setImgData3,
        picture4, setPicture4, imgData4, setImgData4,
        picture5, setPicture5, imgData5, setImgData5,
        picture6, setPicture6, imgData6, setImgData6,
        picture7, setPicture7, imgData7, setImgData7,
        picture8, setPicture8, imgData8, setImgData8,
        picture9, setPicture9, imgData9, setImgData9,
        picture10, setPicture10, imgData10, setImgData10,
    }) => {
    const [imagemAtual, setImagemAtual] = useState(null);

    function handleModal () {
        setModal(false);
    };

    const onChangeName = e => {
        if(imgData === null) {
            setNomeArquivo(e.target.value);
        } else if(imgData2 === null) {
            setNomeArquivo2(e.target.value);
        } else if(imgData3 === null) {
            setNomeArquivo3(e.target.value);
        } else if(imgData4 === null) {
            setNomeArquivo4(e.target.value);
        } else if(imgData5 === null) {
            setNomeArquivo5(e.target.value);
        } else if(imgData6 === null) {
            setNomeArquivo6(e.target.value);
        } else if(imgData7 === null) {
            setNomeArquivo7(e.target.value);
        } else if(imgData8 === null) {
            setNomeArquivo8(e.target.value);
        } else if(imgData9 === null) {
            setNomeArquivo9(e.target.value);
        } else if(imgData10 === null) {
            setNomeArquivo10(e.target.value);
        }
    }

    const onChangePicture = e => {
        if (e.target.files[0]) {
            if(imgData === null) {
                setPicture(e.target.files[0]);
                const reader = new FileReader();
                reader.addEventListener("load", () => {
                  setImgData(reader.result);
                });
                reader.readAsDataURL(e.target.files[0]);
            } else if(imgData2 === null) {
                setPicture2(e.target.files[0]);
                const reader = new FileReader();
                reader.addEventListener("load", () => {
                    setImgData2(reader.result);
                });
                reader.readAsDataURL(e.target.files[0]);
            } else if(imgData3 === null) {
                setPicture3(e.target.files[0]);
                const reader = new FileReader();
                reader.addEventListener("load", () => {
                    setImgData3(reader.result);
                });
                reader.readAsDataURL(e.target.files[0]);
            } else if(imgData4 === null) {
                setPicture4(e.target.files[0]);
                const reader = new FileReader();
                reader.addEventListener("load", () => {
                    setImgData4(reader.result);
                });
                reader.readAsDataURL(e.target.files[0]);
            } else if(imgData5 === null) {
                setPicture5(e.target.files[0]);
                const reader = new FileReader();
                reader.addEventListener("load", () => {
                    setImgData5(reader.result);
                });
                reader.readAsDataURL(e.target.files[0]);
            } else if(imgData6 === null) {
                setPicture6(e.target.files[0]);
                const reader = new FileReader();
                reader.addEventListener("load", () => {
                    setImgData6(reader.result);
                });
                reader.readAsDataURL(e.target.files[0]);
            } else if(imgData7 === null) {
                setPicture7(e.target.files[0]);
                const reader = new FileReader();
                reader.addEventListener("load", () => {
                    setImgData7(reader.result);
                });
                reader.readAsDataURL(e.target.files[0]);
            } else if(imgData8 === null) {
                setPicture8(e.target.files[0]);
                const reader = new FileReader();
                reader.addEventListener("load", () => {
                    setImgData8(reader.result);
                });
                reader.readAsDataURL(e.target.files[0]);
            } else if(imgData9 === null) {
                setPicture9(e.target.files[0]);
                const reader = new FileReader();
                reader.addEventListener("load", () => {
                    setImgData9(reader.result);
                });
                reader.readAsDataURL(e.target.files[0]);
            } else if(imgData10 === null) {
                setPicture10(e.target.files[0]);
                const reader = new FileReader();
                reader.addEventListener("load", () => {
                    setImgData10(reader.result);
                });
                reader.readAsDataURL(e.target.files[0]);
            } 
        }
    };

    useEffect(() => {
        if(imgData === null) {
            setImagemAtual(1);
        } else if(imgData2 === null) {
            setImagemAtual(2);
        } else if(imgData3 === null) {
            setImagemAtual(3);
        } else if(imgData4 === null) {
            setImagemAtual(4);
        } else if(imgData5 === null) {
            setImagemAtual(5);
        } else if(imgData6 === null) {
            setImagemAtual(6);
        } else if(imgData7 === null) {
            setImagemAtual(7);
        } else if(imgData8 === null) {
            setImagemAtual(8);
        } else if(imgData9 === null) {
            setImagemAtual(9);
        } else if(imgData10 === null) {
            setImagemAtual(10);
        }
    }, []);

    return (
        <S.CustomModal>
            <S.DivModal>
                <S.HeaderModal>
                    <S.Left>
                        <img src={AddArquivo} alt="Icone Arquivo" style={{ marginRight: "10px" }} />
                        Anexar arquivos nº{imagemAtual} (PDF, fotos e vídeos)
                    </S.Left>
                    <S.Right onClick={handleModal}>
                        <X />
                    </S.Right>
                </S.HeaderModal>
                <S.ModalBody>
                    <S.Body>
                        <S.CustomInput
                            id="nomeArquivo"
                            type="text"
                            name="nomeArquivo"
                            onChange={onChangeName}
                            value={imagemAtual === 1 ? nomeArquivo : 
                                imagemAtual === 2 ? nomeArquivo2 :
                                imagemAtual === 3 ? nomeArquivo3 :
                                imagemAtual === 4 ? nomeArquivo4 :
                                imagemAtual === 5 ? nomeArquivo5 :
                                imagemAtual === 6 ? nomeArquivo6 :
                                imagemAtual === 7 ? nomeArquivo7 :
                                imagemAtual === 8 ? nomeArquivo8 :
                                imagemAtual === 9 ? nomeArquivo9 :
                                imagemAtual === 10 ? nomeArquivo10 : ""
                            }
                            placeholder="Nome do documento"
                            style={{
                                borderBottomColor: '#CE0F2A',
                                borderBottomWidth: 1,
                                minWidthidth: '100%'
                            }}
                        />
                        <S.DivInput htmlFor='selecao-arquivo'>
                            <Nuvem />
                            {imagemAtual === 1 ?
                                <S.TextInput>{imgData? "Foto selecionada" : "Incluir foto"}</S.TextInput>
                            : imagemAtual === 2 ?
                                <S.TextInput>{imgData2? "Foto selecionada" : "Incluir foto"}</S.TextInput>
                            : imagemAtual === 3 ?
                                <S.TextInput>{imgData3? "Foto selecionada" : "Incluir foto"}</S.TextInput>
                            : imagemAtual === 4 ?
                                <S.TextInput>{imgData4? "Foto selecionada" : "Incluir foto"}</S.TextInput>
                            : imagemAtual === 5 ?
                                <S.TextInput>{imgData5? "Foto selecionada" : "Incluir foto"}</S.TextInput>
                            : imagemAtual === 6 ?
                                <S.TextInput>{imgData6? "Foto selecionada" : "Incluir foto"}</S.TextInput>
                            : imagemAtual === 7 ?
                                <S.TextInput>{imgData7? "Foto selecionada" : "Incluir foto"}</S.TextInput>
                            : imagemAtual === 8 ?
                                <S.TextInput>{imgData8? "Foto selecionada" : "Incluir foto"}</S.TextInput>
                            : imagemAtual === 9 ?
                                <S.TextInput>{imgData9? "Foto selecionada" : "Incluir foto"}</S.TextInput>
                            : imagemAtual === 10 ?
                                <S.TextInput>{imgData10? "Foto selecionada" : "Incluir foto"}</S.TextInput>
                            : ""
                            }
                            <S.CustomInput2 id='selecao-arquivo' type="file" onChange={onChangePicture} />
                        </S.DivInput>
                        <S.DivProximo onClick={handleModal}>Cadastrar arquivo</S.DivProximo>
                    </S.Body>
                </S.ModalBody>
            </S.DivModal>
        </S.CustomModal>
    )
}

export default ModalFull;