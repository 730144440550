import styled from 'styled-components';
import { Input } from 'reactstrap';
import Select from 'react-select'

export const Base = styled.div`
    display: flex;
    justify-content: center;
    width: 100vw;
`;

export const Conteudo = styled.div`
    width: 50%;
    display: grid;
    justify-content: center;
    border-left: 1px solid #ffffff;
    border-right: 1px solid #ffffff;

    @media (max-width: 1000px) {
        width: 60%;
    }

    @media (max-width: 900px) {
        width: 65%;
    }

    @media (max-width: 800px) {
        width: 70%;
    }

    @media (max-width: 700px) {
        width: 75%;
    }

    @media (max-width: 600px) {
        width: 80%;
    }

    @media (max-width: 500px) {
        width: 85%;
    }

    @media (max-width: 400px) {
        width: 90%;
    }
`;

export const DivInputs = styled.div`
    width: 50vw;
    display: grid;
    justify-content: center;
    margin-top: 30px;

    background-color: #00ff00;

    @media (max-width: 1000px) {
        width: 50vw;
    }

    @media (max-width: 900px) {
        width: 55vw;
    }

    @media (max-width: 800px) {
        width: 60vw;
    }

    @media (max-width: 700px) {
        width: 65vw;
    }

    @media (max-width: 600px) {
        width: 70vw;
    }

    @media (max-width: 500px) {
        width: 75vw;
    }

    @media (max-width: 400px) {
        width: 80vw;
        margin-left: 5%;
    }
`;


export const DivButtons = styled.div`
    display: flex;
    height: 60px;
    justify-content: space-between;
    border: 5px solid #80010E;
    border-radius: 50px;
    gap: 40px;
    margin-top: 30px;
    cursor: pointer;
    background-color: #80010E;

    @media (max-width: 500px) {
        gap: 20px;
    }
`;

export const DivReturn = styled.div`
    display: flex;
    justify-content: start;
    margin-left: 10px;
    align-items: center;
    color: #ffffff;
    width: 30%;
    gap: 10px;
    cursor: pointer;

    @media (max-width: 500px) {
        gap: 0px;
        width: 10%;
        margin-left: 4px;
    }
`;

export const DivProximo = styled.div`
    background-color: #ffffff;
    color: #CE0F2A;
    border-radius: 50px;
    font-size: 20px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 70%;
    cursor: pointer;

    @media (max-width: 800px) {
        font-size: 15px;
    }

    @media (max-width: 800px) {
        width: 90%;
    }

    @media (max-width: 800px) {
        width: 90%;
    }

    @media (max-width: 350px) {
        padding-left: 20px;
    }
`;

export const DivFlex = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const DivFlex1 = styled.div`
    display: flex;
    width: 100%;
    gap: 20px;

    @media (max-width: 700px) {
        display: grid
    }
`;

export const DivFlex2 = styled.div`
    display: flex;
    gap: 20px;
    margin-bottom: 10px;

    @media (max-width: 500px) {
        width: 100%;
    }
`;

export const DivFlexImg = styled.div`
    display: flex;
    gap: 20px;
    margin-top: 20px;
    align-items: center;
`;

export const DivButton = styled.div`
    height: 60px;
    border-radius: 50px;
    border: 1px solid #ffffff;
    color: ${({ active }) => { if (active === true) { return '#CE0F2A'; } else { return '#ffffff'; }}};
    align-items: center;
    padding: 0 30px;
    display: flex;
    background-color: ${({ active }) => { if (active === true) { return '#ffffff'; } else { return '#CE0F2A'; }}};
`;

export const TextButton = styled.p`
    font-size: 15px;
    color: #ffffff;
`;

export const DivTextBody = styled.div`
    display: grid;
    justify-content: center;
    margin-bottom: 75px;
    text-align: center;

    @media (max-width: 700px) {
        margin-bottom: 0px;
    }
`;

export const DivTextBody2 = styled.div`
    display: grid;
    justify-content: center;
    margin-bottom: 75px;
    text-align: center;
    align-items: center;
    background-color: #FBE346;
    border-radius: 30px;
    height: 300px;
`;

export const TextBody = styled.p`
    font-size: 20px;
    font-weight: bold;
    color: #CE0F2A;
`;

export const TextLight = styled.text`
    font-size: 15px;
    color: #ffffff;
    text-align: start;
`;

export const TextLightLink = styled.a`
    font-size: 15px;
    color: #ffffff;
    text-align: start;
    color: #FBE346;
    cursor: pointer;
    text-decoration: none;

    &:hover {
        color: #FFFFFF;
    }
`;

export const BordaAdd = styled.div`
    border: 1px solid #ffffff;
    border-radius: 20px;
    padding: 10px;
    cursor: pointer;
    width: 108px;
    height: 108px;
    margin-top: 10px;

    @media (max-width: 500px) {
        width: 40px;
        height: 40px;
        border-radius: 10px;
        padding: 0px;
    }

    &:hover + #teste-hover {
        display: flex;
        width: 349px;
        height: 80px;
        margin-top: 15px;
    }
`;

export const PreView = styled.div`
    width: 108px;
    height: 108px;
    border-radius: 20px;
    border: 1px solid #ffffff;
    object-fit: contain;
`;

export const DivImagens = styled.div`
    z-index: 9999;
    display: none;
`;

export const CustomInput = styled(Input)`
    width: 100%;
    border-radius: 30px;
    box-shadow: none;
    margin-bottom: 30px;

    &:focus {
        border: 1px solid #ff0000;
    }
`;

export const DivInputArea = styled.div`
    width: 100%;
`;

export const TextNone = styled.div`
    @media (max-width: 500px) {
        display: none;
    }
`;

export const CustomInputRadio = styled(Input)`
    width: 30px;
    height: 30px;
`;

export const CustomLabel = styled.p`
    margin-left: 2px;
    margin-top: 8px;
    white-space: nowrap;
`;

export const Grid = styled.div`
    display: grid;
    color: #ffffff;
`;

export const GridTotal = styled.div`
    display: grid;
`;

export const DivButtonClick = styled.div`
    width: 100%;
    color: #ffffff;
    background-color: #80010E;
    height: 70px;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    font-size: 25px;
    font-weight: bold;
    align-items: center;
    cursor: pointer;
    margin-top: -40px;
    margin-bottom: 30px;
`;

export const DivTextBody3 = styled.div`
    display: flex;
    justify-content: center;
    text-align: center;
    border-radius: 30px;
    background-color: #FFEF09;
    width: 100%;
    height: 60px;
    gap: 20px;
`;

export const TextBody2 = styled.span`
    font-size: 16px;
    font-weight: bold;
    color: #CE0F2A;
    display: flex;
    align-items: center;
`;

export const CustomSelect = styled(Select)`
    border-radius: 30px !important;
    background-color: #0000ff;
    margin-bottom: 30px;
`;

export const ImgDestaque = styled.label`
    width: 108px;
    height: 108px;
    border-radius: 20px;
    border: 1px solid #ffffff;
    display: grid;
    justify-content: center;
    align-items: center;
    object-fit: contain;
    margin-top: -15px;
`;

export const X = styled.div`
    width: 25px;
    height: 25px;
    border: 1px solid #ffffff;
    border-radius: 50%;
    color: #ffffff;
    font-size: 15px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #CE0F2A;
`;

export const DivX = styled.div`
    width: 100%:
    height: 20px;
    display: flex;
    justify-content: end;
    position: relative;
    z-indes: 9;
    cursor: pointer;
`;