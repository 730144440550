import React, { useState } from 'react';
import * as S from './styled';
import Alert from '../../assets/imagens/alert.png';
import Return from '../../assets/imagens/return.png';

const Pagina3 = ({ 
        handleClick, BotaoVoltarAoTopo,
        motivo, setMotivo
    }) => {
    const [erro, setErro] = useState(false);
    
    const onCheckboxBtnClick = (selected) => {
        const index = motivo.indexOf(selected);
        if (index < 0) {
            motivo.push(selected);
        } else {
            motivo.splice(index, 1);
        }
        setMotivo([...motivo]);
      };
      
    const handleErro = () => {
        if(motivo.length > 0){
            handleClick(4);
            BotaoVoltarAoTopo();
        } else {
            setErro(true);
            setTimeout(() => {
                setErro(false);
            }, 3000);
        }
    } 
   
    return (
        <S.Base>
            <S.Conteudo>
                <S.DivEncaixe>
                    <S.DivTextBody style={{ marginTop: "30px", marginBottom: "30px" }}>
                        <S.TextBody>Qual o motivo da denúncia?*</S.TextBody>
                        <S.TextButton>(Clique nas opções e selecione um ou mais motivos da denúncia)</S.TextButton>
                    </S.DivTextBody>
                    <S.DivInputs>
                        <S.DivButton onClick={() => onCheckboxBtnClick("Assédio Moral")} active={motivo.includes("Assédio Moral")}>Assédio Moral</S.DivButton>
                        <S.DivButton onClick={() => onCheckboxBtnClick("Assédio Sexual")} active={motivo.includes("Assédio Sexual")}>Assédio Sexual</S.DivButton>
                        <S.DivButton onClick={() => onCheckboxBtnClick("Assalto no local de trabalho")} active={motivo.includes("Assalto no local de trabalho")}>Assalto no local de trabalho</S.DivButton>
                        <S.DivButton onClick={() => onCheckboxBtnClick("Falta de Funcionários ou Terceirização")} active={motivo.includes("Falta de Funcionários ou Terceirização")}>Falta de Funcionários ou Terceirização</S.DivButton>
                        <S.DivButton onClick={() => onCheckboxBtnClick("Descumprimento de Lei ou da Convenção Coletiva de Trabalho (CCT)")} active={motivo.includes("Descumprimento de Lei ou da Convenção Coletiva de Trabalho (CCT)")}>Descumprimento de Lei ou da Convenção Coletiva de Trabalho (CCT)</S.DivButton>
                        <S.DivButton onClick={() => onCheckboxBtnClick("Discriminação (Gênero, Raça, Orientação Sexual, Capacitismo, Etarismo e Outros)")} active={motivo.includes("Discriminação (Gênero, Raça, Orientação Sexual, Capacitismo, Etarismo e Outros)")}>Discriminação (Gênero, Raça, Orientação Sexual, Capacitismo, Etarismo e Outros)</S.DivButton>
                        <S.DivButton onClick={() => onCheckboxBtnClick("Pressão no Local de Trabalho e cobrança metas abusivas")} active={motivo.includes("Pressão no Local de Trabalho e cobrança metas abusivas")}>Pressão no Local de Trabalho e cobrança metas abusivas</S.DivButton>
                        <S.DivButton onClick={() => onCheckboxBtnClick("Problema na Estrutura física do Local de Trabalho (ar condicionado, falta de água, segurança e outros)")} active={motivo.includes("Problema na Estrutura física do Local de Trabalho (ar condicionado, falta de água, segurança e outros)")}>Problema na Estrutura física do Local de Trabalho (ar condicionado, falta de água, segurança e outros)</S.DivButton>
                        <S.DivButton onClick={() => onCheckboxBtnClick("Outro")} active={motivo.includes("Outro")}>Outro</S.DivButton>
                        {erro && (
                            <S.DivTextBody2>
                                <img src={Alert} alt="Icone de Alerta" />
                                <S.TextBody2>Selecione pelo menos 1 motivo</S.TextBody2>
                            </S.DivTextBody2>
                        )}
                        <S.DivButtons>
                            <S.DivReturn onClick={() => handleClick(1)}><img src={Return} alt="Retornar" /><S.TextNone>Retornar</S.TextNone></S.DivReturn>
                            <S.DivProximo onClick={() => handleErro()}>Ir para o proximo passo</S.DivProximo>
                        </S.DivButtons>
                    </S.DivInputs>
                </S.DivEncaixe>
            </S.Conteudo>
        </S.Base>
    );
};

export default Pagina3;