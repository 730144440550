import styled from 'styled-components';

export const Base = styled.div`
    display: flex;
    justify-content: center;
    width: 100vw; 
`;

export const Conteudo = styled.div`
    width: 50vw;
    display: grid;
    justify-content: center;
    border-left: 1px solid #ffffff;
    border-right: 1px solid #ffffff;

    @media (max-width: 1000px) {
        width: 60%;
    }

    @media (max-width: 900px) {
        width: 65%;
    }

    @media (max-width: 800px) {
        width: 70%;
    }

    @media (max-width: 700px) {
        width: 75%;
    }

    @media (max-width: 600px) {
        width: 80%;
    }

    @media (max-width: 500px) {
        width: 85%;
    }

    @media (max-width: 400px) {
        width: 90%;
    }
`;

export const DivEncaixe = styled.div`
    display: grid !important;
    min-width: 50vw;
    display: flex;
    justify-content: center;
`;

export const DivTeste = styled.div`
    width: 40vw;
    height: 100px;
    background-color: #AF0011;
    border: 5px solid #ffffff;
    border-radius: 68px;
    display: flex;
    align-items: center;
    margin-top: 100px;
    margin-bottom: 100px;
    justify-content: center;
    cursor: pointer;

    @media (max-width: 1000px) {
        width: 50vw;
    }

    @media (max-width: 900px) {
        width: 55vw;
    }

    @media (max-width: 800px) {
        width: 60vw;
    }

    @media (max-width: 700px) {
        width: 65vw;
    }

    @media (max-width: 600px) {
        width: 70vw;
    }

    @media (max-width: 500px) {
        width: 75vw;
    }

    @media (max-width: 400px) {
        width: 80vw;
    }
`;

export const Text = styled.text`
    font-size: 25px;
    font-weight: bold;
    color: #ffffff;
    margin-top: 10px;
`;

export const TextLightLink = styled.a`
    font-size: 15px;
    color: #ffffff;
    text-align: start;
    color: #FBE346;
    cursor: pointer;
    text-decoration: none;
    margin-top: -20px;
    margin-bottom: 20px;

    &:hover {
        color: #FFFFFF;
    }
`;

export const DivButtonClick = styled.div`
    width: 40vw;
    color: #80010E;
    background-color: #FFEF09;
    height: 60px;
    border-radius: 68px;
    display: flex;
    justify-content: center;
    font-size: 16px;
    font-weight: bold;
    align-items: center;
    cursor: pointer;
    text-align: center;
    border: 5px solid #80010E;

    @media (max-width: 1000px) {
        width: 50vw;
    }

    @media (max-width: 900px) {
        width: 55vw;
    }

    @media (max-width: 800px) {
        width: 60vw;
    }

    @media (max-width: 700px) {
        width: 65vw;
    }

    @media (max-width: 600px) {
        width: 70vw;
    }

    @media (max-width: 500px) {
        width: 75vw;
    }

    @media (max-width: 400px) {
        width: 80vw;
    }
`;

export const DivLogo = styled.div`
    width: 40vw;
    display: flex;
    justify-content: center;

    @media (max-width: 1000px) {
        margin-left: 5vw;
    }

    @media (max-width: 900px) {
        width: 45vw;
    }

    @media (max-width: 800px) {
        width: 50vw;
    }

    @media (max-width: 700px) {
        width: 55vw;
    }

    @media (max-width: 600px) {
        width: 60vw;
    }

    @media (max-width: 500px) {
        width: 65vw;
    }

    @media (max-width: 400px) {
        width: 70vw;
    }
`;
