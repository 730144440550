import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import Routes from './routes';
import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  body {
    font-family: 'Roboto', sans-serif;

    &::-webkit-scrollbar {
      width: 2px;               /* width of the entire scrollbar */
      height: 2px;               /* width of the entire scrollbar */
    }
    
    &::-webkit-scrollbar-track {
      background: #336AA7;
    }
    
    &::-webkit-scrollbar-thumb {
      background-color: #CE0F2A;    /* color of the scroll thumb */
      border-radius: 20px;       /* roundness of the scroll thumb */
      border: 1px solid #CE0F2A;  /* creates padding around scroll thumb */
    }
  }
`;
// 336AA7
function App() {
  return (
    <>
      <GlobalStyle />
      <Router basename="/">
        <Routes />
      </Router>
    </>
  );
}

export default App;