import styled from 'styled-components';

export const Base = styled.div`
    display: flex;
    justify-content: center;
    width: ${({ scroll }) => { if (scroll === true) { return 'calc(100vw - 20px)'; } else { return '100vw'; }}};
`;
    
export const Conteudo = styled.div`
    background-color: #ffffff;
    width: 50%;
    display: grid;
    justify-content: center;
    border-left: 1px solid #ffffff;
    border-right: 1px solid #ffffff;

    @media (max-width: 1000px) {
        width: 60%;
    }

    @media (max-width: 900px) {
        width: 65%;
    }

    @media (max-width: 800px) {
        width: 70%;
    }

    @media (max-width: 700px) {
        width: 75%;
    }

    @media (max-width: 600px) {
        width: 80%;
    }

    @media (max-width: 500px) {
        width: 85%;
    }

    @media (max-width: 400px) {
        width: 90%;
    }
`;

export const DivGrid = styled.div`
    display: grid;
`;

export const DivGridCenter = styled.div`
    display: grid;
    align-items: center;
    height: 60px;
    margin-top: 42px;
    gap: 5px;
    cursor: pointer;
`;

export const DivFlex = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 10px;
`;

export const DivFlexCenter = styled.div`
    display: flex;
    justify-content: center;
    gap: 10px;
`;

export const TextBody = styled.span`
    font-size: 20px;
    font-weight: 500;
    color: #7E181A;
`;

export const TextBodyWhite = styled.span`
    font-size: 20px;
    font-weight: 500;
    color: #ffffff;
`;

export const CustomLink = styled.a`
    font-size: 20px;
    font-weight: 500;
    color: #7E181A;
    display: grid;
    text-decoration: none;
`;

export const TextLight = styled.span`
    font-size: 11px;
    font-weight: 300;
    color: #E34548;
    margin-top: 10px;
`;

export const TextLightwhite = styled.span`
    font-size: 11px;
    font-weight: 300;
    color: #ffffff;
    margin-top: 10px;
`;

export const TextLightPlus = styled.span`
    font-size: 8px;
    font-weight: 300;
    color: #E34548;
`;

export const DivAcesso = styled.div`
    width: 100%;
    height: 145px;
    
    display: grid;
    padding: 15px 0;
`;

export const DivFlexAlert = styled.div`
    display: flex;
    height: 145px;
    justify-content: space-between;
    gap: 10px;
    background-color: #7E181A;
    border: 1px solid #A11D20;
    border-radius: 30px;
`;

export const DivGridAlert = styled.div`
    width: 100%;
    height: 100%;
    background-color: #7E181A;
    border: 1px solid #A11D20;
    border-radius: 30px;
    padding: 20px;
`;

export const DivButtonClick = styled.div`
    width: 100%;
    color: #AF0011;
    background-color: #FFEF09;
    height: 30px;
    border-radius: 68px;
    display: flex;
    justify-content: center;
    font-size: 11px;
    font-weight: bold;
    align-items: center;
    cursor: pointer;
`;