import styled from 'styled-components';
import { Input } from 'reactstrap';
import InputMask from "react-input-mask";

export const Base = styled.div`
    display: flex;
    justify-content: center;
    background-color: #FBE346;
    border-radius: 30px;
    padding: 0 20px;
    margin-top: -20px;
`;

export const Conteudo = styled.div`
    width: 100%;
    display: grid;
    justify-content: center;
`;

export const CustomInput = styled(Input)`
    width: 100%;
    height: 40px;
    border-radius: 20px;
    border: 1px solid #CE0F2A;
    margin-top: -15px;

    &:focus {
        box-shadow: 0 0 0 0;
        outline: 0;
        border: 2px solid #80010E;
    } 

    // @media (max-width: 1000px) {
    //     width: 50vw;
    // }

    // @media (max-width: 900px) {
    //     width: 55vw;
    // }

    // @media (max-width: 800px) {
    //     width: 60vw;
    // }

    // @media (max-width: 700px) {
    //     width: 65vw;
    // }

    // @media (max-width: 600px) {
    //     width: 70vw;
    // }

    // @media (max-width: 500px) {
    //     width: 75vw;
    //     margin-left: 5%;
    // }

    // @media (max-width: 400px) {
    //     width: 80vw;
    //     margin-left: 5%;
    // }
`;

export const CustomInput2 = styled(InputMask)`
    width: 100%;
    height: 40px;
    border-radius: 20px;
    border: 1px solid #CE0F2A;
    padding-left: 5%;
    margin-top: -15px;

    &:focus {
        box-shadow: 0 0 0 0;
        outline: 0;
        border: 2px solid #80010E;
    } 

    // @media (max-width: 1000px) {
    //     width: 50vw;
    // }

    // @media (max-width: 900px) {
    //     width: 55vw;
    // }

    // @media (max-width: 800px) {
    //     width: 60vw;
    // }

    // @media (max-width: 700px) {
    //     width: 65vw;
    // }

    // @media (max-width: 600px) {
    //     width: 70vw;
    // }

    // @media (max-width: 500px) {
    //     width: 75vw;
    //     margin-left: 5%;
    // }

    // @media (max-width: 400px) {
    //     width: 80vw;
    //     margin-left: 5%;
    // }
`;

export const TextLight = styled.p`
    font-size: 12px;
    color: #CE0F2A;
    margin-top: 30px;
    min-width: 100%;

    @media (max-width: 500px) {
        margin-left: 5%;
    }
`;

export const TextBody = styled.p`
    font-size: 25px;
    font-weight: bold;
    color: #CE0F2A;
    text-align: center;
`;

export const DivFlex = styled.div`
    display: flex;
    gap: 40px;

    @media (max-width: 500px) {
        width: 85%;
        gap: 10px;
        margin-left: 5%;
    }
`;

export const DivFlex2 = styled.div`
    display: flex;
    gap: 40px;

    @media (max-width: 500px) {
        width: 85%;
        gap: 5px;
        margin-left: 2%;
    }
`;

export const DivButtons = styled.div`
    display: flex;
    height: 60px;
    justify-content: space-between;
    border: 3px solid #AF0011;
    border-radius: 50px;
    gap: 40px;
    margin-top: 60px;
    width: 100%;

    @media (max-width: 500px) {
        gap: 20px;
    }

    @media (max-width: 400px) {
        gap: 10px;
    }
`;

export const DivReturn = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    width: 30%;
    gap: 10px;
    padding-left: 10px;
    cursor: pointer;
    font-weight: bold;

    @media (max-width: 500px) {
        gap: 0px;
        width: 10%;
        margin-left: 4px;
    }
`;

export const DivProximo = styled.div`
    background-color: #ffffff;
    color: #AF0011;
    border-radius: 50px;
    font-size: 1.25rem;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 70%;
    cursor: pointer;
    border: 3px solid #AF0011;
    text-align: center;

    @media (max-width: 500px) {
        width: 90%;
    }
`;

export const TextNone = styled.div`
    @media (max-width: 500px) {
        display: none;
    }
`;

export const CustomInputRadio = styled(Input)`
    width: 30px;
    height: 30px;
`;

export const CustomLabel = styled.p`
    margin-left: 10px;
    margin-top: 8px;
    color: #CE0F2A;
`;

export const DivTitle = styled.div`
    width: 100%;
    margin-top: 30px;
`;

export const X = styled.div`
    width: 50px;
    height: 50px;
    border: 1px solid #CE0F2A;
    border-radius: 50%;
    color: #CE0F2A;
    font-size: 30px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #FBE346;
`;

export const DivX = styled.div`
    width: 100%:
    height: 20px;
    display: flex;
    justify-content: end;
    position: relative;
    z-indes: 9;
    cursor: pointer;
`;

export const Not = styled.text`
    font-size: 15px;
    margin-right: 10px;
    color: #ffffff;
    margin-top: 5px;
`;
