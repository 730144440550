import React from 'react';
import * as S from './styled';
// import LogoCanal from '../../assets/imagens/canal.png';
import LogoTeste from '../../assets/svg/LogoTeste';

const Header = ({pagina}) => {
    return (
      <>
        <S.FundoRedTopo>
          <S.Borda pagina={pagina}>
              {pagina === 1 || pagina === 7 ? 
                  ""
              :
                <S.Esconde>
                  {/* <img src={LogoCanal} alt="Canal de denúncias" />  */}
                  <LogoTeste width="200" height="70" />
                </S.Esconde>
              }
          </S.Borda>
        </S.FundoRedTopo>
      </>
    );
};

export default Header;