import React, { useState } from 'react';
import * as S from './styled';
import { Input, FormGroup, Label, Row, Col, Container } from 'reactstrap';
import Return from '../../assets/imagens/return.png';
import IconArquivos from '../../assets/svg/IconArquivos';
import Add from '../../assets/imagens/add.png';
import ModalFull from '../../components/ModalFull';
import Pagina2 from '../../components/Pagina2';
import Alert from '../../assets/imagens/alert.png';

const Pagina4 = ({ 
        handleClick, setAcompanhada, acompanhada,
        denunciado, setDenunciado, 
        banco, setBanco, agencia, setAgencia, cidade2, setCidade2, 
        relato, setRelato, 
        nomeArquivo, setNomeArquivo,
        nomeArquivo2, setNomeArquivo2,
        nomeArquivo3, setNomeArquivo3,
        nomeArquivo4, setNomeArquivo4,
        nomeArquivo5, setNomeArquivo5,
        nomeArquivo6, setNomeArquivo6,
        nomeArquivo7, setNomeArquivo7,
        nomeArquivo8, setNomeArquivo8,
        nomeArquivo9, setNomeArquivo9,
        nomeArquivo10, setNomeArquivo10,
        picture, setPicture, imgData, setImgData,
        picture2, setPicture2, imgData2, setImgData2,
        picture3, setPicture3, imgData3, setImgData3,
        picture4, setPicture4, imgData4, setImgData4,
        picture5, setPicture5, imgData5, setImgData5,
        picture6, setPicture6, imgData6, setImgData6,
        picture7, setPicture7, imgData7, setImgData7,
        picture8, setPicture8, imgData8, setImgData8,
        picture9, setPicture9, imgData9, setImgData9,
        picture10, setPicture10, imgData10, setImgData10,
        nome, setNome, nomeSocial, setNomeSocial,
        celular, setCelular, email, setEmail,
        genero, setGenero, raca, setRaca,
        cargo, setCargo, doenca, setDoenca,
        afastamento, setAfastamento, office, setOffice,
        termo, setTermo,
        handleSendForm, BotaoVoltarAoTopo,
    }) => {
    const [modal, setModal] = useState(false);
    const [clique, setClique] = useState(false);
    const [erro, setErro] = useState(false);

    const options = [
        { value: 'Aceguá', label: 'Aceguá' },
        { value: 'Água Santa', label: 'Água Santa' },
        { value: 'Agudo', label: 'Agudo' },
        { value: 'Ajuricaba', label: 'Ajuricaba' },
        { value: 'Alecrim', label: 'Alecrim' },
        { value: 'Alegrete', label: 'Alegrete' },
        { value: 'Alegria', label: 'Alegria' },
        { value: 'Almirante Tamandaré do Sul', label: 'Almirante Tamandaré do Sul' },
        { value: 'Alpestre', label: 'Alpestre' },
        { value: 'Alto Alegre', label: 'Alto Alegre' },
        { value: 'Alto Feliz', label: 'Alto Feliz' },
        { value: 'Alvorada', label: 'Alvorada' },
        { value: 'Amaral Ferrador', label: 'Amaral Ferrador' },
        { value: 'Ametista do Sul', label: 'Ametista do Sul' },
        { value: 'André da Rocha', label: 'André da Rocha' },
        { value: 'Anta Gorda', label: 'Anta Gorda' },
        { value: 'Antônio Prado', label: 'Antônio Prado' },
        { value: 'Arambaré', label: 'Arambaré' },
        { value: 'Araricá', label: 'Araricá' },
        { value: 'Aratiba', label: 'Aratiba' },
        { value: 'Arroio do Meio', label: 'Arroio do Meio' },
        { value: 'Arroio do Padre', label: 'Arroio do Padre' },
        { value: 'Arroio do Sal', label: 'Arroio do Sal' },
        { value: 'Arroio do Tigre', label: 'Arroio do Tigre' },
        { value: 'Arroio dos Ratos', label: 'Arroio dos Ratos' },
        { value: 'Arroio Grande', label: 'Arroio Grande' },
        { value: 'Arvorezinha', label: 'Arvorezinha' },
        { value: 'Augusto Pestana', label: 'Augusto Pestana' },
        { value: 'Áurea', label: 'Áurea' },
        { value: 'Bagé', label: 'Bagé' },
        { value: 'Balneário Pinhal', label: 'Balneário Pinhal' },
        { value: 'Barão', label: 'Barão' },
        { value: 'Barão de Cotegipe', label: 'Barão de Cotegipe' },
        { value: 'Barão do Triunfo', label: 'Barão do Triunfo' },
        { value: 'Barra do Guarita', label: 'Barra do Guarita' },
        { value: 'Barra do Quaraí', label: 'Barra do Quaraí' },
        { value: 'Barra do Ribeiro', label: 'Barra do Ribeiro' },
        { value: 'Barra do Rio Azul', label: 'Barra do Rio Azul' },
        { value: 'Barra Funda', label: 'Barra Funda' },
        { value: 'Barracão', label: 'Barracão' },
        { value: 'Barros Cassal', label: 'Barros Cassal' },
        { value: 'Benjamin Constant do Sul', label: 'Benjamin Constant do Sul' },
        { value: 'Bento Gonçalves', label: 'Bento Gonçalves' },
        { value: 'Boa Vista das Missões', label: 'Boa Vista das Missões' },
        { value: 'Boa Vista do Buricá', label: 'Boa Vista do Buricá' },
        { value: 'Boa Vista do Cadeado', label: 'Boa Vista do Cadeado' },
        { value: 'Boa Vista do Incra', label: 'Boa Vista do Incra' },
        { value: 'Boa Vista do Sul', label: 'Boa Vista do Sul' },
        { value: 'Bom Jesus', label: 'Bom Jesus' },
        { value: 'Bom Princípio', label: 'Bom Princípio' },
        { value: 'Bom Progresso', label: 'Bom Progresso' },
        { value: 'Bom Retiro do Sul', label: 'Bom Retiro do Sul' },
        { value: 'Boqueirão do Leão', label: 'Boqueirão do Leão' },
        { value: 'Bossoroca', label: 'Bossoroca' },
        { value: 'Bozano', label: 'Bozano' },
        { value: 'Braga', label: 'Braga' },
        { value: 'Brochier', label: 'Brochier' },
        { value: 'Butiá', label: 'Butiá' },
        { value: 'Caçapava do Sul', label: 'Caçapava do Sul' },
        { value: 'Cacequi', label: 'Cacequi' },
        { value: 'Cachoeira do Sul', label: 'Cachoeira do Sul' },
        { value: 'Cachoeirinha', label: 'Cachoeirinha' },
        { value: 'Cacique Doble', label: 'Cacique Doble' },
        { value: 'Caibaté', label: 'Caibaté' },
        { value: 'Caiçara', label: 'Caiçara' },
        { value: 'Camaquã', label: 'Camaquã' },
        { value: 'Camargo', label: 'Camargo' },
        { value: 'Cambará do Sul', label: 'Cambará do Sul' },
        { value: 'Campestre da Serra', label: 'Campestre da Serra' },
        { value: 'Campina das Missões', label: 'Campina das Missões' },
        { value: 'Campinas do Sul', label: 'Campinas do Sul' },
        { value: 'Campo Bom', label: 'Campo Bom' },
        { value: 'Campo Novo', label: 'Campo Novo' },
        { value: 'Campos Borges', label: 'Campos Borges' },
        { value: 'Candelária', label: 'Candelária' },
        { value: 'Cândido Godói', label: 'Cândido Godói' },
        { value: 'Candiota', label: 'Candiota' },
        { value: 'Canela', label: 'Canela' },
        { value: 'Canguçu', label: 'Canguçu' },
        { value: 'Canoas', label: 'Canoas' },
        { value: 'Canudos do Vale', label: 'Canudos do Vale' },
        { value: 'Capão Bonito do Sul', label: 'Capão Bonito do Sul' },
        { value: 'Capão da Canoa', label: 'Capão da Canoa' },
        { value: 'Capão do Cipó', label: 'Capão do Cipó' },
        { value: 'Capão do Leão', label: 'Capão do Leão' },
        { value: 'Capela de Santana', label: 'Capela de Santana' },
        { value: 'Capitão', label: 'Capitão' },
        { value: 'Capivari do Sul', label: 'Capivari do Sul' },
        { value: 'Caraá', label: 'Caraá' },
        { value: 'Carazinho', label: 'Carazinho' },
        { value: 'Carlos Barbosa', label: 'Carlos Barbosa' },
        { value: 'Carlos Gomes', label: 'Carlos Gomes' },
        { value: 'Casca', label: 'Casca' },
        { value: 'Caseiros', label: 'Caseiros' },
        { value: 'Catuípe', label: 'Catuípe' },
        { value: 'Caxias do Sul', label: 'Caxias do Sul' },
        { value: 'Centenário', label: 'Centenário' },
        { value: 'Cerrito', label: 'Cerrito' },
        { value: 'Cerro Branco', label: 'Cerro Branco' },
        { value: 'Cerro Grande do Sul', label: 'Cerro Grande do Sul' },
        { value: 'Cerro Grande', label: 'Cerro Grande' },
        { value: 'Cerro Largo', label: 'Cerro Largo' },
        { value: 'Chapada', label: 'Chapada' },
        { value: 'Charqueadas', label: 'Charqueadas' },
        { value: 'Charrua', label: 'Charrua' },
        { value: 'Chiapetta', label: 'Chiapetta' },
        { value: 'Chuí', label: 'Chuí' },
        { value: 'Chuvisca', label: 'Chuvisca' },
        { value: 'Cidreira', label: 'Cidreira' },
        { value: 'Ciríaco', label: 'Ciríaco' },
        { value: 'Colinas', label: 'Colinas' },
        { value: 'Colorado', label: 'Colorado' },
        { value: 'Condor', label: 'Condor' },
        { value: 'Constantina', label: 'Constantina' },
        { value: 'Coqueiro Baixo', label: 'Coqueiro Baixo' },
        { value: 'Coqueiros do Sul', label: 'Coqueiros do Sul' },
        { value: 'Coronel Barros', label: 'Coronel Barros' },
        { value: 'Coronel Bicaco', label: 'Coronel Bicaco' },
        { value: 'Coronel Pilar', label: 'Coronel Pilar' },
        { value: 'Cotiporã', label: 'Cotiporã' },
        { value: 'Coxilha', label: 'Coxilha' },
        { value: 'Crissiumal', label: 'Crissiumal' },
        { value: 'Cristal', label: 'Cristal' },
        { value: 'Cristal do Sul', label: 'Cristal do Sul' },
        { value: 'Cruz Alta', label: 'Cruz Alta' },
        { value: 'Cruzaltense', label: 'Cruzaltense' },
        { value: 'Cruzeiro do Sul', label: 'Cruzeiro do Sul' },
        { value: 'David Canabarro', label: 'David Canabarro' },
        { value: 'Derrubadas', label: 'Derrubadas' },
        { value: 'Dezesseis de Novembro', label: 'Dezesseis de Novembro' },
        { value: 'Dilermando de Aguiar', label: 'Dilermando de Aguiar' },
        { value: 'Dois Irmãos das Missões', label: 'Dois Irmãos das Missões' },
        { value: 'Dois Irmãos', label: 'Dois Irmãos' },
        { value: 'Dois Lajeados', label: 'Dois Lajeados' },
        { value: 'Dom Feliciano', label: 'Dom Feliciano' },
        { value: 'Dom Pedrito', label: 'Dom Pedrito' },
        { value: 'Dom Pedro de Alcântara', label: 'Dom Pedro de Alcântara' },
        { value: 'Dona Francisca', label: 'Dona Francisca' },
        { value: 'Doutor Maurício Cardoso', label: 'Doutor Maurício Cardoso' },
        { value: 'Doutor Ricardo', label: 'Doutor Ricardo' },
        { value: 'Eldorado do Sul', label: 'Eldorado do Sul' },
        { value: 'Encantado', label: 'Encantado' },
        { value: 'Encruzilhada do Sul', label: 'Encruzilhada do Sul' },
        { value: 'Engenho Velho', label: 'Engenho Velho' },
        { value: 'Entre Rios do Sul', label: 'Entre Rios do Sul' },
        { value: 'Entre-Ijuís ', label: 'Entre-Ijuís ' },
        { value: 'Erebango', label: 'Erebango' },
        { value: 'Erechim', label: 'Erechim' },
        { value: 'Ernestina', label: 'Ernestina' },
        { value: 'Erval Grande', label: 'Erval Grande' },
        { value: 'Erval Seco', label: 'Erval Seco' },
        { value: 'Esmeralda', label: 'Esmeralda' },
        { value: 'Esperança do Sul', label: 'Esperança do Sul' },
        { value: 'Espumoso', label: 'Espumoso' },
        { value: 'Estação', label: 'Estação' },
        { value: 'Estância Velha', label: 'Estância Velha' },
        { value: 'Esteio', label: 'Esteio' },
        { value: 'Estrela', label: 'Estrela' },
        { value: 'Estrela Velha', label: 'Estrela Velha' },
        { value: 'Eugênio de Castro', label: 'Eugênio de Castro' },
        { value: 'Fagundes Varela', label: 'Fagundes Varela' },
        { value: 'Farroupilha', label: 'Farroupilha' },
        { value: 'Faxinal do Soturno', label: 'Faxinal do Soturno' },
        { value: 'Faxinalzinho', label: 'Faxinalzinho' },
        { value: 'Fazenda Vilanova', label: 'Fazenda Vilanova' },
        { value: 'Feliz', label: 'Feliz' },
        { value: 'Flores da Cunha', label: 'Flores da Cunha' },
        { value: 'Floriano Peixoto', label: 'Floriano Peixoto' },
        { value: 'Fontoura Xavier', label: 'Fontoura Xavier' },
        { value: 'Formigueiro', label: 'Formigueiro' },
        { value: 'Forquetinha', label: 'Forquetinha' },
        { value: 'Fortaleza dos Valos', label: 'Fortaleza dos Valos' },
        { value: 'Frederico Westphalen', label: 'Frederico Westphalen' },
        { value: 'Garibaldi', label: 'Garibaldi' },
        { value: 'Garruchos', label: 'Garruchos' },
        { value: 'Gaurama', label: 'Gaurama' },
        { value: 'General Câmara', label: 'General Câmara' },
        { value: 'Gentil', label: 'Gentil' },
        { value: 'Getúlio Vargas', label: 'Getúlio Vargas' },
        { value: 'Giruá', label: 'Giruá' },
        { value: 'Glorinha', label: 'Glorinha' },
        { value: 'Gramado dos Loureiros', label: 'Gramado dos Loureiros' },
        { value: 'Gramado Xavier', label: 'Gramado Xavier' },
        { value: 'Gramado', label: 'Gramado' },
        { value: 'Gravataí', label: 'Gravataí' },
        { value: 'Guabiju', label: 'Guabiju' },
        { value: 'Guaíba', label: 'Guaíba' },
        { value: 'Guaporé', label: 'Guaporé' },
        { value: 'Guarani das Missões', label: 'Guarani das Missões' },
        { value: 'Harmonia', label: 'Harmonia' },
        { value: 'Herval', label: 'Herval' },
        { value: 'Herveiras', label: 'Herveiras' },
        { value: 'Horizontina', label: 'Horizontina' },
        { value: 'Hulha Negra', label: 'Hulha Negra' },
        { value: 'Humaitá', label: 'Humaitá' },
        { value: 'Ibarama', label: 'Ibarama' },
        { value: 'Ibiaçá', label: 'Ibiaçá' },
        { value: 'Ibiraiaras', label: 'Ibiraiaras' },
        { value: 'Ibirapuitã', label: 'Ibirapuitã' },
        { value: 'Ibirubá', label: 'Ibirubá' },
        { value: 'Igrejinha', label: 'Igrejinha' },
        { value: 'Ijuí', label: 'Ijuí' },
        { value: 'Ilópolis', label: 'Ilópolis' },
        { value: 'Imbé', label: 'Imbé' },
        { value: 'Imigrante', label: 'Imigrante' },
        { value: 'Independência', label: 'Independência' },
        { value: 'Inhacorá', label: 'Inhacorá' },
        { value: 'Ipê', label: 'Ipê' },
        { value: 'Ipiranga do Sul', label: 'Ipiranga do Sul' },
        { value: 'Iraí', label: 'Iraí' },
        { value: 'Itaara', label: 'Itaara' },
        { value: 'Itacurubi', label: 'Itacurubi' },
        { value: 'Itapuca', label: 'Itapuca' },
        { value: 'Itaqui', label: 'Itaqui' },
        { value: 'Itati', label: 'Itati' },
        { value: 'Itatiba do Sul', label: 'Itatiba do Sul' },
        { value: 'Ivorá', label: 'Ivorá' },
        { value: 'Ivoti', label: 'Ivoti' },
        { value: 'Jaboticaba', label: 'Jaboticaba' },
        { value: 'Jacuizinho', label: 'Jacuizinho' },
        { value: 'Jacutinga', label: 'Jacutinga' },
        { value: 'Jaguarão', label: 'Jaguarão' },
        { value: 'Jaguari', label: 'Jaguari' },
        { value: 'Jaquirana', label: 'Jaquirana' },
        { value: 'Jari', label: 'Jari' },
        { value: 'Jóia', label: 'Jóia' },
        { value: 'Júlio de Castilhos', label: 'Júlio de Castilhos' },
        { value: 'Lagoa Bonita do Sul', label: 'Lagoa Bonita do Sul' },
        { value: 'Lagoa dos Três Cantos', label: 'Lagoa dos Três Cantos' },
        { value: 'Lagoa Vermelha', label: 'Lagoa Vermelha' },
        { value: 'Lagoão', label: 'Lagoão' },
        { value: 'Lajeado', label: 'Lajeado' },
        { value: 'Lajeado do Bugre', label: 'Lajeado do Bugre' },
        { value: 'Lavras do Sul', label: 'Lavras do Sul' },
        { value: 'Liberato Salzano', label: 'Liberato Salzano' },
        { value: 'Lindolfo Collor', label: 'Lindolfo Collor' },
        { value: 'Linha Nova', label: 'Linha Nova' },
        { value: 'Maçambará', label: 'Maçambará' },
        { value: 'Machadinho', label: 'Machadinho' },
        { value: 'Mampituba', label: 'Mampituba' },
        { value: 'Manoel Viana', label: 'Manoel Viana' },
        { value: 'Maquiné', label: 'Maquiné' },
        { value: 'Maratá', label: 'Maratá' },
        { value: 'Marau', label: 'Marau' },
        { value: 'Marcelino Ramos', label: 'Marcelino Ramos' },
        { value: 'Mariana Pimentel', label: 'Mariana Pimentel' },
        { value: 'Mariano Moro', label: 'Mariano Moro' },
        { value: 'Marques de Souza', label: 'Marques de Souza' },
        { value: 'Mata', label: 'Mata' },
        { value: 'Mato Castelhano', label: 'Mato Castelhano' },
        { value: 'Mato Leitão', label: 'Mato Leitão' },
        { value: 'Mato Queimado', label: 'Mato Queimado' },
        { value: 'Maximiliano de Almeida', label: 'Maximiliano de Almeida' },
        { value: 'Minas do Leão', label: 'Minas do Leão' },
        { value: 'Miraguaí', label: 'Miraguaí' },
        { value: 'Montauri', label: 'Montauri' },
        { value: 'Monte Alegre dos Campos', label: 'Monte Alegre dos Campos' },
        { value: 'Monte Belo do Sul', label: 'Monte Belo do Sul' },
        { value: 'Montenegro', label: 'Montenegro' },
        { value: 'Mormaço', label: 'Mormaço' },
        { value: 'Morrinhos do Sul', label: 'Morrinhos do Sul' },
        { value: 'Morro Redondo', label: 'Morro Redondo' },
        { value: 'Morro Reuter', label: 'Morro Reuter' },
        { value: 'Mostardas', label: 'Mostardas' },
        { value: 'Muçum', label: 'Muçum' },
        { value: 'Muitos Capões', label: 'Muitos Capões' },
        { value: 'Muliterno', label: 'Muliterno' },
        { value: 'Não-Me-Toque', label: 'Não-Me-Toque' },
        { value: 'Nicolau Vergueiro', label: 'Nicolau Vergueiro' },
        { value: 'Nonoai', label: 'Nonoai' },
        { value: 'Nova Alvorada', label: 'Nova Alvorada' },
        { value: 'Nova Araçá', label: 'Nova Araçá' },
        { value: 'Nova Bassano', label: 'Nova Bassano' },
        { value: 'Nova Boa Vista', label: 'Nova Boa Vista' },
        { value: 'Nova Bréscia', label: 'Nova Bréscia' },
        { value: 'Nova Candelária', label: 'Nova Candelária' },
        { value: 'Nova Esperança do Sul', label: 'Nova Esperança do Sul' },
        { value: 'Nova Hartz', label: 'Nova Hartz' },
        { value: 'Nova Pádua', label: 'Nova Pádua' },
        { value: 'Nova Palma', label: 'Nova Palma' },
        { value: 'Nova Petrópolis', label: 'Nova Petrópolis' },
        { value: 'Nova Prata', label: 'Nova Prata' },
        { value: 'Nova Ramada', label: 'Nova Ramada' },
        { value: 'Nova Roma do Sul', label: 'Nova Roma do Sul' },
        { value: 'Nova Santa Rita', label: 'Nova Santa Rita' },
        { value: 'Novo Barreiro', label: 'Novo Barreiro' },
        { value: 'Novo Cabrais', label: 'Novo Cabrais' },
        { value: 'Novo Hamburgo', label: 'Novo Hamburgo' },
        { value: 'Novo Machado', label: 'Novo Machado' },
        { value: 'Novo Tiradentes', label: 'Novo Tiradentes' },
        { value: 'Novo Xingu', label: 'Novo Xingu' },
        { value: 'Osório', label: 'Osório' },
        { value: 'Paim Filho', label: 'Paim Filho' },
        { value: 'Palmares do Sul', label: 'Palmares do Sul' },
        { value: 'Palmeira das Missões', label: 'Palmeira das Missões' },
        { value: 'Palmitinho', label: 'Palmitinho' },
        { value: 'Panambi', label: 'Panambi' },
        { value: 'Pantano Grande', label: 'Pantano Grande' },
        { value: 'Paraí', label: 'Paraí' },
        { value: 'Paraíso do Sul', label: 'Paraíso do Sul' },
        { value: 'Pareci Novo', label: 'Pareci Novo' },
        { value: 'Parobé', label: 'Parobé' },
        { value: 'Passa-Sete ', label: 'Passa-Sete ' },
        { value: 'Passo do Sobrado', label: 'Passo do Sobrado' },
        { value: 'Passo Fundo', label: 'Passo Fundo' },
        { value: 'Paulo Bento', label: 'Paulo Bento' },
        { value: 'Paverama', label: 'Paverama' },
        { value: 'Pedras Altas', label: 'Pedras Altas' },
        { value: 'Pedro Osório', label: 'Pedro Osório' },
        { value: 'Pejuçara', label: 'Pejuçara' },
        { value: 'Pelotas', label: 'Pelotas' },
        { value: 'Picada Café', label: 'Picada Café' },
        { value: 'Pinhal', label: 'Pinhal' },
        { value: 'Pinhal da Serra', label: 'Pinhal da Serra' },
        { value: 'Pinhal Grande', label: 'Pinhal Grande' },
        { value: 'Pinheirinho do Vale', label: 'Pinheirinho do Vale' },
        { value: 'Pinheiro Machado', label: 'Pinheiro Machado' },
        { value: 'Pinto Bandeira', label: 'Pinto Bandeira' },
        { value: 'Pirapó', label: 'Pirapó' },
        { value: 'Piratini', label: 'Piratini' },
        { value: 'Planalto', label: 'Planalto' },
        { value: 'Poço das Antas', label: 'Poço das Antas' },
        { value: 'Pontão', label: 'Pontão' },
        { value: 'Ponte Preta', label: 'Ponte Preta' },
        { value: 'Portão', label: 'Portão' },
        { value: 'Porto Alegre', label: 'Porto Alegre' },
        { value: 'Porto Lucena', label: 'Porto Lucena' },
        { value: 'Porto Mauá', label: 'Porto Mauá' },
        { value: 'Porto Vera Cruz', label: 'Porto Vera Cruz' },
        { value: 'Porto Xavier', label: 'Porto Xavier' },
        { value: 'Pouso Novo', label: 'Pouso Novo' },
        { value: 'Presidente Lucena', label: 'Presidente Lucena' },
        { value: 'Progresso', label: 'Progresso' },
        { value: 'Protásio Alves', label: 'Protásio Alves' },
        { value: 'Putinga', label: 'Putinga' },
        { value: 'Quaraí', label: 'Quaraí' },
        { value: 'Quatro Irmãos', label: 'Quatro Irmãos' },
        { value: 'Quevedos', label: 'Quevedos' },
        { value: 'Quinze de Novembro', label: 'Quinze de Novembro' },
        { value: 'Redentora', label: 'Redentora' },
        { value: 'Relvado', label: 'Relvado' },
        { value: 'Restinga Sêca', label: 'Restinga Sêca' },
        { value: 'Rio dos Índios', label: 'Rio dos Índios' },
        { value: 'Rio Grande', label: 'Rio Grande' },
        { value: 'Rio Pardo', label: 'Rio Pardo' },
        { value: 'Riozinho', label: 'Riozinho' },
        { value: 'Roca Sales', label: 'Roca Sales' },
        { value: 'Rodeio Bonito', label: 'Rodeio Bonito' },
        { value: 'Rolador', label: 'Rolador' },
        { value: 'Rolante', label: 'Rolante' },
        { value: 'Ronda Alta', label: 'Ronda Alta' },
        { value: 'Rondinha', label: 'Rondinha' },
        { value: 'Roque Gonzales', label: 'Roque Gonzales' },
        { value: 'Rosário do Sul', label: 'Rosário do Sul' },
        { value: 'Sagrada Família', label: 'Sagrada Família' },
        { value: 'Saldanha Marinho', label: 'Saldanha Marinho' },
        { value: 'Salto do Jacuí', label: 'Salto do Jacuí' },
        { value: 'Salvador das Missões', label: 'Salvador das Missões' },
        { value: 'Salvador do Sul', label: 'Salvador do Sul' },
        { value: 'Sananduva', label: 'Sananduva' },
        { value: 'Santa Bárbara do Sul', label: 'Santa Bárbara do Sul' },
        { value: 'Santa Cecília do Sul', label: 'Santa Cecília do Sul' },
        { value: 'Santa Clara do Sul', label: 'Santa Clara do Sul' },
        { value: 'Santa Cruz do Sul', label: 'Santa Cruz do Sul' },
        { value: 'Santa Margarida do Sul', label: 'Santa Margarida do Sul' },
        { value: 'Santa Maria', label: 'Santa Maria' },
        { value: 'Santa Maria do Herval', label: 'Santa Maria do Herval' },
        { value: 'Santa Rosa', label: 'Santa Rosa' },
        { value: 'Santa Tereza', label: 'Santa Tereza' },
        { value: 'Santa Vitória do Palmar', label: 'Santa Vitória do Palmar' },
        { value: 'Santana da Boa Vista', label: 'Santana da Boa Vista' },
        { value: 'Santana do Livramento', label: 'Santana do Livramento' },
        { value: 'Santiago', label: 'Santiago' },
        { value: 'Santo Ângelo', label: 'Santo Ângelo' },
        { value: 'Santo Antônio da Patrulha', label: 'Santo Antônio da Patrulha' },
        { value: 'Santo Antônio das Missões', label: 'Santo Antônio das Missões' },
        { value: 'Santo Antônio do Palma', label: 'Santo Antônio do Palma' },
        { value: 'Santo Antônio do Planalto', label: 'Santo Antônio do Planalto' },
        { value: 'Santo Augusto', label: 'Santo Augusto' },
        { value: 'Santo Cristo', label: 'Santo Cristo' },
        { value: 'Santo Expedito do Sul', label: 'Santo Expedito do Sul' },
        { value: 'São Borja', label: 'São Borja' },
        { value: 'São Domingos do Sul', label: 'São Domingos do Sul' },
        { value: 'São Francisco de Assis', label: 'São Francisco de Assis' },
        { value: 'São Francisco de Paula', label: 'São Francisco de Paula' },
        { value: 'São Gabriel', label: 'São Gabriel' },
        { value: 'São Jerônimo', label: 'São Jerônimo' },
        { value: 'São João da Urtiga', label: 'São João da Urtiga' },
        { value: 'São João do Polêsine', label: 'São João do Polêsine' },
        { value: 'São Jorge', label: 'São Jorge' },
        { value: 'São José das Missões', label: 'São José das Missões' },
        { value: 'São José do Herval', label: 'São José do Herval' },
        { value: 'São José do Hortêncio', label: 'São José do Hortêncio' },
        { value: 'São José do Inhacorá', label: 'São José do Inhacorá' },
        { value: 'São José do Norte', label: 'São José do Norte' },
        { value: 'São José do Ouro', label: 'São José do Ouro' },
        { value: 'São José do Sul', label: 'São José do Sul' },
        { value: 'São José dos Ausentes', label: 'São José dos Ausentes' },
        { value: 'São Leopoldo', label: 'São Leopoldo' },
        { value: 'São Lourenço do Sul', label: 'São Lourenço do Sul' },
        { value: 'São Luiz Gonzaga', label: 'São Luiz Gonzaga' },
        { value: 'São Marcos', label: 'São Marcos' },
        { value: 'São Martinho', label: 'São Martinho' },
        { value: 'São Martinho da Serra', label: 'São Martinho da Serra' },
        { value: 'São Miguel das Missões', label: 'São Miguel das Missões' },
        { value: 'São Nicolau', label: 'São Nicolau' },
        { value: 'São Paulo das Missões', label: 'São Paulo das Missões' },
        { value: 'São Pedro da Serra', label: 'São Pedro da Serra' },
        { value: 'São Pedro das Missões', label: 'São Pedro das Missões' },
        { value: 'São Pedro do Butiá', label: 'São Pedro do Butiá' },
        { value: 'São Pedro do Sul', label: 'São Pedro do Sul' },
        { value: 'São Sebastião do Caí', label: 'São Sebastião do Caí' },
        { value: 'São Sepé', label: 'São Sepé' },
        { value: 'São Valentim', label: 'São Valentim' },
        { value: 'São Valentim do Sul', label: 'São Valentim do Sul' },
        { value: 'São Valério do Sul', label: 'São Valério do Sul' },
        { value: 'São Vendelino', label: 'São Vendelino' },
        { value: 'São Vicente do Sul', label: 'São Vicente do Sul' },
        { value: 'Sapiranga', label: 'Sapiranga' },
        { value: 'Sapucaia do Sul', label: 'Sapucaia do Sul' },
        { value: 'Sarandi', label: 'Sarandi' },
        { value: 'Seberi', label: 'Seberi' },
        { value: 'Sede Nova', label: 'Sede Nova' },
        { value: 'Segredo', label: 'Segredo' },
        { value: 'Selbach', label: 'Selbach' },
        { value: 'Senador Salgado Filho', label: 'Senador Salgado Filho' },
        { value: 'Sentinela do Sul', label: 'Sentinela do Sul' },
        { value: 'Serafina Corrêa', label: 'Serafina Corrêa' },
        { value: 'Sério', label: 'Sério' },
        { value: 'Sertão', label: 'Sertão' },
        { value: 'Sertão Santana', label: 'Sertão Santana' },
        { value: 'Sete de Setembro', label: 'Sete de Setembro' },
        { value: 'Severiano de Almeida', label: 'Severiano de Almeida' },
        { value: 'Silveira Martins', label: 'Silveira Martins' },
        { value: 'Sinimbu', label: 'Sinimbu' },
        { value: 'Sobradinho', label: 'Sobradinho' },
        { value: 'Soledade', label: 'Soledade' },
        { value: 'Tabaí', label: 'Tabaí' },
        { value: 'Tapejara', label: 'Tapejara' },
        { value: 'Tapera', label: 'Tapera' },
        { value: 'Tapes', label: 'Tapes' },
        { value: 'Taquara', label: 'Taquara' },
        { value: 'Taquari', label: 'Taquari' },
        { value: 'Taquaruçu do Sul', label: 'Taquaruçu do Sul' },
        { value: 'Tavares', label: 'Tavares' },
        { value: 'Tenente Portela', label: 'Tenente Portela' },
        { value: 'Terra de Areia', label: 'Terra de Areia' },
        { value: 'Teutônia', label: 'Teutônia' },
        { value: 'Tio Hugo', label: 'Tio Hugo' },
        { value: 'Tiradentes do Sul', label: 'Tiradentes do Sul' },
        { value: 'Toropi', label: 'Toropi' },
        { value: 'Torres', label: 'Torres' },
        { value: 'Tramandaí', label: 'Tramandaí' },
        { value: 'Travesseiro', label: 'Travesseiro' },
        { value: 'Três Arroios', label: 'Três Arroios' },
        { value: 'Três Cachoeiras', label: 'Três Cachoeiras' },
        { value: 'Três Coroas', label: 'Três Coroas' },
        { value: 'Três de Maio', label: 'Três de Maio' },
        { value: 'Três Forquilhas', label: 'Três Forquilhas' },
        { value: 'Três Palmeiras', label: 'Três Palmeiras' },
        { value: 'Três Passos', label: 'Três Passos' },
        { value: 'Trindade do Sul', label: 'Trindade do Sul' },
        { value: 'Triunfo', label: 'Triunfo' },
        { value: 'Tucunduva', label: 'Tucunduva' },
        { value: 'Tunas', label: 'Tunas' },
        { value: 'Tupanci do Sul', label: 'Tupanci do Sul' },
        { value: 'Tupanciretã', label: 'Tupanciretã' },
        { value: 'Tupandi', label: 'Tupandi' },
        { value: 'Tuparendi', label: 'Tuparendi' },
        { value: 'Turuçu', label: 'Turuçu' },
        { value: 'Ubiretama', label: 'Ubiretama' },
        { value: 'União da Serra', label: 'União da Serra' },
        { value: 'Unistalda', label: 'Unistalda' },
        { value: 'Uruguaiana', label: 'Uruguaiana' },
        { value: 'Vacaria', label: 'Vacaria' },
        { value: 'Vale do Sol', label: 'Vale do Sol' },
        { value: 'Vale Real', label: 'Vale Real' },
        { value: 'Vale Verde', label: 'Vale Verde' },
        { value: 'Vanini', label: 'Vanini' },
        { value: 'Venâncio Aires', label: 'Venâncio Aires' },
        { value: 'Vera Cruz', label: 'Vera Cruz' },
        { value: 'Veranópolis', label: 'Veranópolis' },
        { value: 'Vespasiano Corrêa', label: 'Vespasiano Corrêa' },
        { value: 'Viadutos', label: 'Viadutos' },
        { value: 'Viamão', label: 'Viamão' },
        { value: 'Vicente Dutra', label: 'Vicente Dutra' },
        { value: 'Victor Graeff', label: 'Victor Graeff' },
        { value: 'Vila Flores', label: 'Vila Flores' },
        { value: 'Vila Lângaro', label: 'Vila Lângaro' },
        { value: 'Vila Maria', label: 'Vila Maria' },
        { value: 'Vila Nova do Sul', label: 'Vila Nova do Sul' },
        { value: 'Vista Alegre do Prata', label: 'Vista Alegre do Prata' },
        { value: 'Vista Alegre', label: 'Vista Alegre' },
        { value: 'Vista Gaúcha', label: 'Vista Gaúcha' },
        { value: 'Vitória das Missões', label: 'Vitória das Missões' },
        { value: 'Westfália', label: 'Westfália' },
        { value: 'Xangri-lá ', label: 'Xangri-lá ' },
    ]


    const customStyles = {
        control: (provided) => ({
          ...provided,
          borderRadius: '30px',
        }),
        menu: (provided) => ({
          ...provided,
          marginTop: '0px',
        }),
    };

    const handleErro = () => {
        if (!clique) {
            if(banco && agencia && cidade2 && relato && (termo === true)){
                handleSendForm();
                BotaoVoltarAoTopo();
            } else {
                setErro(true);
                setTimeout(() => {
                    setErro(false);
                }, 3000);
            }
        } else {
            if(banco && agencia && cidade2 && relato && (termo === true) && nome && celular && email && genero && raca && cargo && doenca && afastamento && office){
                handleSendForm();
                BotaoVoltarAoTopo();
            } else {
                setErro(true);
                setTimeout(() => {
                    setErro(false);
                }, 3000);
            }
        }
    }

    const handleTypeSelect = e => {
        setCidade2(e.value);
    };

    return (
        <S.Base>
            <S.Conteudo>
                <S.DivTextBody>
                    <S.TextBody>Dados da denúncia</S.TextBody>
                </S.DivTextBody>
                <Container style={{ padding: "0px 5%", minWidth: "50vw" }}>
                    <Row>
                        <S.TextLight>O denunciado é?</S.TextLight>
                         <Col lg={12} xl={5} xxl={4}>
                            <FormGroup check>
                                <Label check style={{ color: "#ffffff", display: "flex" }}>
                                    <S.CustomInputRadio type="radio" name="denunciado" value="denunciaInstituicao" checked={denunciado === "denunciaInstituicao"} onChange={(e) => setDenunciado(e.target.value)} style={{ backgroundColor: "#CE0F2A", border: "1px solid #ffffff" }} />
                                    <S.CustomLabel>
                                        Instituição Financeira
                                    </S.CustomLabel>
                                </Label>
                            </FormGroup>
                        </Col>
                        <Col lg={12} xl={4} xxl={4}>
                            <FormGroup>
                                <Label check style={{ color: "#ffffff", display: "flex" }}>
                                    <S.CustomInputRadio type="radio" name="denunciado" value="denunciaFuncionario" checked={denunciado === "denunciaFuncionario"} onChange={(e) => setDenunciado(e.target.value)} style={{ backgroundColor: "#CE0F2A", border: "1px solid #ffffff" }} />
                                    <S.CustomLabel>
                                        Funcionário(a)
                                    </S.CustomLabel>
                                </Label>
                            </FormGroup>
                        </Col>
                        <Col lg={12} xl={3} xxl={4}>
                            <FormGroup>
                                <Label check style={{ color: "#ffffff", display: "flex" }}>
                                    <S.CustomInputRadio type="radio" name="denunciado" value="denunciaTerceiro" checked={denunciado === "denunciaTerceiro"} onChange={(e) => setDenunciado(e.target.value)} style={{ backgroundColor: "#CE0F2A", border: "1px solid #ffffff" }} />
                                    <S.CustomLabel>
                                        Terceirizado
                                    </S.CustomLabel>
                                </Label>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} xl={4} xxl={6}>
                            <S.TextLight>Banco*</S.TextLight>
                            <S.CustomInput
                                id="banco"
                                type="text"
                                name="banco"
                                onChange={(e) => setBanco(e.target.value)}
                                value={banco}
                            />
                        </Col>
                        <Col lg={12} xl={4} xxl={6}>
                            <S.TextLight>Agência*</S.TextLight>
                            <S.CustomInput
                                id="agencia"
                                type="text"
                                name="agencia"
                                onChange={(e) => setAgencia(e.target.value)}
                                value={agencia}
                            />
                        </Col>
                        <Col lg={12} xl={4} xxl={12}>
                            <S.TextLight>Cidade*</S.TextLight>
                            <S.CustomSelect 
                                id="cidade2"
                                name="cidade2"
                                options={options}
                                placeholder={""}
                                styles={customStyles}
                                onChange={handleTypeSelect}
                                value={options.filter(function(option) {
                                    return option.value === cidade2;
                                })}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <S.TextLight>Relate sua denúncia*</S.TextLight>
                            <S.DivInputArea>
                                <S.CustomInput
                                    id="exampleText"
                                    name="text"
                                    type="textarea"
                                    onChange={(e) => setRelato(e.target.value)}
                                    rows="10"
                                />
                            </S.DivInputArea>
                        </Col>
                    </Row>
                    <Row>
                        <S.DivFlexImg>
                            <S.TextLight style={{ marginBottom: "20px", marginTop: "30px" }}>Anexar arquivos (PDF, fotos e vídeos)</S.TextLight>
                            <IconArquivos />
                        </S.DivFlexImg>
                    </Row>
                    <Row>
                        <S.GridTotal>
                            <S.DivFlex2>
                                {picture && (
                                    <S.Grid>
                                        <S.DivX onClick={() => {setPicture(null);setImgData(null);setNomeArquivo(null)}}>
                                            <S.X>X</S.X>
                                        </S.DivX>
                                        <S.ImgDestaque>
                                            <img src={imgData} alt="" height={"108px"} width={"108px"} style={{ borderRadius: "20px" }} />
                                        </S.ImgDestaque>
                                        {nomeArquivo}
                                    </S.Grid>
                                )}
                                {picture2 && (
                                    <S.Grid>
                                        <S.DivX onClick={() => {setPicture2(null);setImgData2(null);setNomeArquivo2(null)}}>
                                            <S.X>X</S.X>
                                        </S.DivX>
                                        <S.ImgDestaque>
                                            <img src={imgData2} alt="" height={"108px"} width={"108px"} style={{ borderRadius: "20px" }} />
                                        </S.ImgDestaque>
                                        {nomeArquivo2}
                                    </S.Grid>
                                )}
                                {picture3 && (
                                    <S.Grid>
                                        <S.DivX onClick={() => {setPicture3(null);setImgData3(null);setNomeArquivo3(null)}}>
                                            <S.X>X</S.X>
                                        </S.DivX>
                                        <S.ImgDestaque>
                                            <img src={imgData3} alt="" height={"108px"} width={"108px"} style={{ borderRadius: "20px" }} />
                                        </S.ImgDestaque>
                                        {nomeArquivo3}
                                    </S.Grid>
                                )}
                                {picture3 === null ?
                                    <S.DivFlex2>
                                        <S.BordaAdd onClick={() => setModal(true)}>
                                            <img src={Add} alt="Add" width={"100%"} />
                                        </S.BordaAdd>
                                    </S.DivFlex2>
                                : ""
                                }
                            </S.DivFlex2>
                            <S.DivFlex2>
                                {picture4 && (
                                    <S.Grid>
                                        <S.DivX onClick={() => {setPicture4(null);setImgData4(null);setNomeArquivo4(null)}}>
                                            <S.X>X</S.X>
                                        </S.DivX>
                                        <S.ImgDestaque>
                                            <img src={imgData4} alt="" height={"108px"} width={"108px"} style={{ borderRadius: "20px" }} />
                                        </S.ImgDestaque>
                                        {nomeArquivo4}
                                    </S.Grid>
                                )}
                                {picture5 && (
                                    <S.Grid>
                                        <S.DivX onClick={() => {setPicture5(null);setImgData5(null);setNomeArquivo5(null)}}>
                                            <S.X>X</S.X>
                                        </S.DivX>
                                        <S.ImgDestaque>
                                            <img src={imgData5} alt="" height={"108px"} width={"108px"} style={{ borderRadius: "20px" }} />
                                        </S.ImgDestaque>
                                        {nomeArquivo5}
                                    </S.Grid>
                                )}
                                {picture6 && (
                                    <S.Grid>
                                        <S.DivX onClick={() => {setPicture6(null);setImgData6(null);setNomeArquivo6(null)}}>
                                            <S.X>X</S.X>
                                        </S.DivX>
                                        <S.ImgDestaque>
                                            <img src={imgData6} alt="" height={"108px"} width={"108px"} style={{ borderRadius: "20px" }} />
                                        </S.ImgDestaque>
                                        {nomeArquivo6}
                                    </S.Grid>
                                )}
                                {picture3 !== null & picture6 === null ?
                                    <S.DivFlex2>
                                        <S.BordaAdd onClick={() => setModal(true)}>
                                            <img src={Add} alt="Add" width={"100%"} />
                                        </S.BordaAdd>
                                    </S.DivFlex2> : ""
                                }
                            </S.DivFlex2>
                            <S.DivFlex2>
                                {picture7 && (
                                    <S.Grid>
                                        <S.DivX onClick={() => {setPicture7(null);setImgData7(null);setNomeArquivo7(null)}}>
                                            <S.X>X</S.X>
                                        </S.DivX>
                                        <S.ImgDestaque>
                                            <img src={imgData7} alt="" height={"108px"} width={"108px"} style={{ borderRadius: "20px" }} />
                                        </S.ImgDestaque>
                                        {nomeArquivo7}
                                    </S.Grid>
                                )}
                                {picture8 && (
                                    <S.Grid>
                                        <S.DivX onClick={() => {setPicture8(null);setImgData8(null);setNomeArquivo8(null)}}>
                                            <S.X>X</S.X>
                                        </S.DivX>
                                        <S.ImgDestaque>
                                            <img src={imgData8} alt="" height={"108px"} width={"108px"} style={{ borderRadius: "20px" }} />
                                        </S.ImgDestaque>
                                        {nomeArquivo8}
                                    </S.Grid>
                                )}
                                {picture9 && (
                                    <S.Grid>
                                        <S.DivX onClick={() => {setPicture9(null);setImgData9(null);setNomeArquivo9(null)}}>
                                            <S.X>X</S.X>
                                        </S.DivX>
                                        <S.ImgDestaque>
                                            <img src={imgData9} alt="" height={"108px"} width={"108px"} style={{ borderRadius: "20px" }} />
                                        </S.ImgDestaque>
                                        {nomeArquivo9}
                                    </S.Grid>
                                )}
                                {picture6 !== null & picture9 === null ?
                                    <S.DivFlex2>
                                        <S.BordaAdd onClick={() => setModal(true)}>
                                            <img src={Add} alt="Add" width={"100%"} />
                                        </S.BordaAdd>
                                    </S.DivFlex2> : ""
                                }
                            </S.DivFlex2>
                            <S.DivFlex2>
                                {picture10 && (
                                    <S.Grid>
                                        <S.DivX onClick={() => {setPicture10(null);setImgData10(null);setNomeArquivo10(null)}}>
                                            <S.X>X</S.X>
                                        </S.DivX>
                                        <S.ImgDestaque>
                                            <img src={imgData10} alt="" height={"108px"} width={"108px"} style={{ borderRadius: "20px" }} />
                                        </S.ImgDestaque>
                                        {nomeArquivo10}
                                    </S.Grid>
                                )}
                                {picture9 !== null & picture10 === null ?
                                    <S.DivFlex2>
                                        <S.BordaAdd onClick={() => setModal(true)}>
                                            <img src={Add} alt="Add" width={"100%"} />
                                        </S.BordaAdd>
                                    </S.DivFlex2> : ""
                                }
                            </S.DivFlex2>
                        </S.GridTotal>
                    </Row>
                    {!clique && (
                        <Row style={{ marginTop: "50px"}}>
                            <S.DivTextBody2>
                                <S.TextBody>Caso queira acompanhar e receber os andamentos da sua denúncia, você precisa se identificar</S.TextBody>
                                <S.DivButtonClick onClick={() => {setClique(!clique);setAcompanhada(!acompanhada)}}>
                                    Quero me identificar
                                </S.DivButtonClick>
                            </S.DivTextBody2>
                        </Row>
                    )}
                    {clique && (
                        <Pagina2 
                            nome={nome} setNome={setNome} 
                            nomeSocial={nomeSocial} setNomeSocial={setNomeSocial}
                            celular={celular} setCelular={setCelular}
                            email={email} setEmail={setEmail}
                            genero={genero} setGenero={setGenero} 
                            raca={raca} setRaca={setRaca}
                            cargo={cargo} setCargo={setCargo}
                            doenca={doenca} setDoenca={setDoenca}
                            afastamento={afastamento} setAfastamento={setAfastamento}
                            office={office} setOffice={setOffice}
                            clique={clique} setClique={setClique}
                        />
                    )}
                    <Row>
                        <S.DivFlex2 style={{ marginTop: "20px" }}>
                            <Input 
                                type="checkbox" 
                                checked={termo} 
                                onChange={e => setTermo(!termo)}
                                style={{ backgroundColor: "#ff0000", border: "1px solid #ffffff" }}
                            />
                            <S.TextLight>Estou ciente e concordo com os 
                                <S.TextLightLink href="https://www.teste.com.br/" target="_blank"> termos de uso*</S.TextLightLink>
                            </S.TextLight>
                        </S.DivFlex2>
                    </Row>
                    {erro && (
                        <S.DivTextBody3>
                            <img src={Alert} alt="Icone de Alerta" />
                            <S.TextBody2>Prencha os campos obrigatórios</S.TextBody2>
                        </S.DivTextBody3>
                    )}
                    <Row>
                        <S.DivButtons>
                            <S.DivReturn onClick={() => handleClick(3)}><img src={Return} alt="Retornar" /><S.TextNone>Retornar</S.TextNone></S.DivReturn>
                            <S.DivProximo onClick={() => handleErro()}>Enviar sua denúncia</S.DivProximo>
                        </S.DivButtons>
                    </Row>
                </Container>
              </S.Conteudo>
            {modal && 
                <ModalFull
                    handleClick={handleClick}
                    modal={modal} setModal={setModal} 
                    nomeArquivo={nomeArquivo} setNomeArquivo={setNomeArquivo}
                    nomeArquivo2={nomeArquivo2} setNomeArquivo2={setNomeArquivo2}
                    nomeArquivo3={nomeArquivo3} setNomeArquivo3={setNomeArquivo3}
                    nomeArquivo4={nomeArquivo4} setNomeArquivo4={setNomeArquivo4}
                    nomeArquivo5={nomeArquivo5} setNomeArquivo5={setNomeArquivo5}
                    nomeArquivo6={nomeArquivo6} setNomeArquivo6={setNomeArquivo6}
                    nomeArquivo7={nomeArquivo7} setNomeArquivo7={setNomeArquivo7}
                    nomeArquivo8={nomeArquivo8} setNomeArquivo8={setNomeArquivo8}
                    nomeArquivo9={nomeArquivo9} setNomeArquivo9={setNomeArquivo9}
                    nomeArquivo10={nomeArquivo10} setNomeArquivo10={setNomeArquivo10}
                    picture={picture} setPicture={setPicture} 
                    imgData={imgData} setImgData={setImgData} 
                    picture2={picture2} setPicture2={setPicture2}
                    imgData2={imgData2} setImgData2={setImgData2}
                    picture3={picture3} setPicture3={setPicture3}
                    imgData3={imgData3} setImgData3={setImgData3}
                    picture4={picture4} setPicture4={setPicture4}
                    imgData4={imgData4} setImgData4={setImgData4}
                    picture5={picture5} setPicture5={setPicture5}
                    imgData5={imgData5} setImgData5={setImgData5}
                    picture6={picture6} setPicture6={setPicture6}
                    imgData6={imgData6} setImgData6={setImgData6}
                    picture7={picture7} setPicture7={setPicture7}
                    imgData7={imgData7} setImgData7={setImgData7}
                    picture8={picture8} setPicture8={setPicture8}
                    imgData8={imgData8} setImgData8={setImgData8}
                    picture9={picture9} setPicture9={setPicture9}
                    imgData9={imgData9} setImgData9={setImgData9}
                    picture10={picture10} setPicture10={setPicture10}
                    imgData10={imgData10} setImgData10={setImgData10}
                />
            }
        </S.Base>
    );
};

export default Pagina4;