import styled from 'styled-components';

export const FundoRedTopo = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    height: 105px;
    background-color: #CE0F2A;
`;

export const Borda = styled.div`
    width: 50%;
    background-color: ${({ pagina }) => { if (pagina === 7) { return '#ffffff'; } else { return '#CE0F2A'; }}};
    display: flex;
    justify-content: center;
    border: 1px solid #ffffff;
    border-radius: 30px 30px 0 0;
    margin-top: 35px;
    border-bottom: #CE0F2A;

    @media (max-width: 1000px) {
        width: 60%;
    }

    @media (max-width: 900px) {
        width: 65%;
    }

    @media (max-width: 800px) {
        width: 70%;
    }

    @media (max-width: 700px) {
        width: 75%;
    }

    @media (max-width: 600px) {
        width: 80%;
    }

    @media (max-width: 500px) {
        width: 85%;
    }

    @media (max-width: 400px) {
        width: 90%;
    }
`;

export const Esconde = styled.div`
    background-color: #CE0F2A;
    position: relative;
    z-index: 3;
    padding: 0 20px 35px 20px;
    margin-top: -35px;
`;
