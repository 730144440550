import React from 'react';

function IconArquivos(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="218.5" height="54.339" viewBox="0 0 218.5 54.339" fill="none" {...props}>
            <defs>
                <clipPath id="clip-path">
                <rect id="Retângulo_2865" data-name="Retângulo 2865" width="28.881" height="36.876" fill="none"/>
                </clipPath>
                <clipPath id="clip-path-3">
                <rect id="Retângulo_2839" data-name="Retângulo 2839" width="22.126" height="20.245" fill="#fff"/>
                </clipPath>
                <clipPath id="clip-path-4">
                <rect id="Retângulo_2867" data-name="Retângulo 2867" width="22.995" height="13.162" fill="#fff"/>
                </clipPath>
            </defs>
            <g id="Grupo_4308" data-name="Grupo 4308" transform="translate(-2382.029 -1272)">
                <path id="União_2" data-name="União 2" d="M27.17,0H191.33a27.17,27.17,0,0,1,0,54.339H27.17A27.17,27.17,0,0,1,27.17,0Z" transform="translate(2382.03 1272)" fill="#80010e"/>
                <g id="Grupo_3777" data-name="Grupo 3777" transform="translate(2416.278 1280.151)">
                <g id="Grupo_3766" data-name="Grupo 3766" clipPath="url(#clip-path)">
                    <path id="Caminho_1464" data-name="Caminho 1464" d="M.03,30.831A5.9,5.9,0,0,0,4.182,36.57a5.447,5.447,0,0,0,1.642.287c3.4.022,6.872.02,10.229.018h.135c2.924-.032,7.367-.086,7.775-.1a6.047,6.047,0,0,0,4.914-5.943.536.536,0,0,0-.518-.539.557.557,0,0,0-.6.515l-.006.072a6.315,6.315,0,0,1-.177,1.186,4.966,4.966,0,0,1-5,3.7c-4.791,0-10.474,0-16.291,0a5.792,5.792,0,0,1-1.5-.194,4.926,4.926,0,0,1-3.652-4.971c0-9.71,0-17.208,0-24.315a5.677,5.677,0,0,1,.194-1.5A4.921,4.921,0,0,1,6.3,1.11c5.891-.006,11.218,0,16.291,0a5.537,5.537,0,0,1,1.5.189,4.923,4.923,0,0,1,3.681,4.952q0,4.556,0,9.112v6.214c0,.691.413.691.548.691a.52.52,0,0,0,.4-.142.711.711,0,0,0,.167-.493V18.587c0-4.124.006-8.387-.006-12.579A5.9,5.9,0,0,0,24.9.366a9.65,9.65,0,0,0-.941-.272L23.888.077,23.589,0H5.418a1.619,1.619,0,0,1-.251.067,5.9,5.9,0,0,0-4.5,3.265A5.893,5.893,0,0,0,.009,6L0,6.417.024,9.229V19.264c0,3.827,0,7.723.006,11.568" fill="#fff"/>
                    <path id="Caminho_1465" data-name="Caminho 1465" d="M31.713,47.579c-1.877,0-3.674,0-5.407,0-2.627,0-5.105,0-7.489.009a.76.76,0,0,0-.284.064.544.544,0,0,0-.262.715.546.546,0,0,0,.488.32c1.53.007,3.094.005,4.607,0h4.75c1.183,0,2.385,0,3.572,0a.587.587,0,0,0,.433-.15.557.557,0,0,0,.178-.391.564.564,0,0,0-.158-.41.6.6,0,0,0-.414-.155" transform="translate(-10.821 -28.249)" fill="#fff"/>
                    <path id="Caminho_1466" data-name="Caminho 1466" d="M31.707,38.351c-1.876,0-3.666,0-5.384,0-2.657,0-5.142,0-7.511.009a.762.762,0,0,0-.281.064.544.544,0,0,0,.228,1.035c1.451.006,2.93,0,4.367,0h5.027c1.172,0,2.362,0,3.537,0a.562.562,0,0,0,.45-.951.614.614,0,0,0-.418-.156" transform="translate(-10.82 -22.77)" fill="#fff"/>
                    <path id="Caminho_1467" data-name="Caminho 1467" d="M31.713,56.616c-1.877,0-3.674,0-5.407,0-2.627,0-5.105,0-7.489.009a.761.761,0,0,0-.284.064.544.544,0,0,0-.262.715.546.546,0,0,0,.488.32c1.53.007,3.094.005,4.607,0h4.75c1.183,0,2.385,0,3.572,0a.587.587,0,0,0,.433-.15.557.557,0,0,0,.178-.391.564.564,0,0,0-.158-.41.6.6,0,0,0-.414-.155" transform="translate(-10.821 -33.615)" fill="#fff"/>
                    <path id="Caminho_1468" data-name="Caminho 1468" d="M24.318,65.613c-1.941-.008-3.742-.007-5.506,0a.554.554,0,1,0-.052,1.106h.054c.576.006,1.162.005,1.733,0h2.339c.47,0,.948,0,1.418,0a.565.565,0,0,0,.426-.151.554.554,0,0,0-.366-.956" transform="translate(-10.819 -38.956)" fill="#fff"/>
                    <path id="Caminho_1469" data-name="Caminho 1469" d="M16.3,34.173a.561.561,0,0,1,.57-.592.593.593,0,0,1,0,1.185.562.562,0,0,1-.57-.592" transform="translate(-9.678 -19.94)" fill="#fff"/>
                    <path id="Caminho_1470" data-name="Caminho 1470" d="M21.949,23.863a7.929,7.929,0,0,1,1.41-.106,2.29,2.29,0,0,1,1.493.413,1.429,1.429,0,0,1,.5,1.118,1.532,1.532,0,0,1-.435,1.148,2.231,2.231,0,0,1-1.6.54,2.569,2.569,0,0,1-.45-.03V28.85h-.915Zm.915,2.354a1.836,1.836,0,0,0,.45.038c.69,0,1.11-.338,1.11-.93,0-.563-.39-.863-1.027-.863a2.558,2.558,0,0,0-.533.045Z" transform="translate(-13.033 -14.106)" fill="#fff"/>
                    <path id="Caminho_1471" data-name="Caminho 1471" d="M33.194,23.863a9.271,9.271,0,0,1,1.47-.105,3.078,3.078,0,0,1,2.062.585,2.262,2.262,0,0,1,.788,1.86,2.617,2.617,0,0,1-.788,2.01,3.376,3.376,0,0,1-2.287.69,10,10,0,0,1-1.245-.068Zm.923,4.282a3.458,3.458,0,0,0,.51.022,1.731,1.731,0,0,0,1.912-1.935,1.6,1.6,0,0,0-1.792-1.755,2.941,2.941,0,0,0-.63.053Z" transform="translate(-19.71 -14.107)" fill="#fff"/>
                    <path id="Caminho_1472" data-name="Caminho 1472" d="M46.729,23.85h2.917v.758H47.651V26h1.86v.758h-1.86V28.9h-.923Z" transform="translate(-27.747 -14.161)" fill="#fff"/>
                </g>
                </g>
                <g id="Grupo_3775" data-name="Grupo 3775" transform="translate(2458.763 1280.151)">
                <g id="Grupo_3768" data-name="Grupo 3768" clipPath="url(#clip-path)">
                    <path id="Caminho_1473" data-name="Caminho 1473" d="M.03,30.831A5.9,5.9,0,0,0,4.182,36.57a5.447,5.447,0,0,0,1.642.287c3.4.022,6.872.02,10.229.018h.135c2.924-.032,7.367-.086,7.775-.1a6.047,6.047,0,0,0,4.914-5.943.536.536,0,0,0-.518-.539.557.557,0,0,0-.6.515l-.006.072a6.315,6.315,0,0,1-.177,1.186,4.966,4.966,0,0,1-5,3.7c-4.791,0-10.474,0-16.291,0a5.793,5.793,0,0,1-1.5-.194,4.926,4.926,0,0,1-3.652-4.971c0-9.71,0-17.208,0-24.315a5.677,5.677,0,0,1,.194-1.5A4.921,4.921,0,0,1,6.3,1.11c5.891-.006,11.218,0,16.291,0a5.537,5.537,0,0,1,1.5.189,4.923,4.923,0,0,1,3.681,4.952q0,4.556,0,9.112v6.214c0,.691.413.691.548.691a.52.52,0,0,0,.4-.142.711.711,0,0,0,.167-.493V18.587c0-4.124.006-8.387-.006-12.579A5.9,5.9,0,0,0,24.9.366a9.65,9.65,0,0,0-.941-.272L23.888.077,23.589,0H5.418a1.619,1.619,0,0,1-.251.067,5.9,5.9,0,0,0-4.5,3.265A5.893,5.893,0,0,0,.009,6L0,6.417.024,9.229V19.264c0,3.827,0,7.723.006,11.568" fill="#fff"/>
                    <path id="Caminho_1474" data-name="Caminho 1474" d="M31.713,47.579c-1.877,0-3.674,0-5.407,0-2.627,0-5.105,0-7.489.009a.761.761,0,0,0-.284.064.544.544,0,0,0-.262.715.546.546,0,0,0,.488.32c1.53.007,3.094.005,4.607,0h4.75c1.183,0,2.385,0,3.572,0a.587.587,0,0,0,.433-.15.557.557,0,0,0,.178-.391.564.564,0,0,0-.158-.41.6.6,0,0,0-.414-.155" transform="translate(-10.821 -28.249)" fill="#fff"/>
                    <path id="Caminho_1475" data-name="Caminho 1475" d="M31.707,38.351c-1.876,0-3.666,0-5.384,0-2.657,0-5.142,0-7.511.009a.762.762,0,0,0-.281.064.544.544,0,0,0,.228,1.035c1.451.006,2.93,0,4.367,0h5.027c1.172,0,2.362,0,3.537,0a.562.562,0,0,0,.45-.951.614.614,0,0,0-.418-.156" transform="translate(-10.82 -22.77)" fill="#fff"/>
                    <path id="Caminho_1476" data-name="Caminho 1476" d="M31.713,56.616c-1.877,0-3.674,0-5.407,0-2.627,0-5.105,0-7.489.009a.761.761,0,0,0-.284.064.544.544,0,0,0-.262.715.546.546,0,0,0,.488.32c1.53.007,3.094.005,4.607,0h4.75c1.183,0,2.385,0,3.572,0a.587.587,0,0,0,.433-.15.557.557,0,0,0,.178-.391.564.564,0,0,0-.158-.41.6.6,0,0,0-.414-.155" transform="translate(-10.821 -33.615)" fill="#fff"/>
                    <path id="Caminho_1477" data-name="Caminho 1477" d="M24.318,65.613c-1.941-.008-3.742-.007-5.506,0a.554.554,0,1,0-.052,1.106h.054c.576.006,1.162.005,1.733,0h2.339c.47,0,.948,0,1.418,0a.565.565,0,0,0,.426-.151.554.554,0,0,0-.366-.956" transform="translate(-10.819 -38.956)" fill="#fff"/>
                    <path id="Caminho_1478" data-name="Caminho 1478" d="M14.23,34.173a.561.561,0,0,1,.57-.592.593.593,0,0,1,0,1.185.562.562,0,0,1-.57-.592" transform="translate(-8.449 -19.94)" fill="#fff"/>
                    <path id="Caminho_1479" data-name="Caminho 1479" d="M19.881,23.863a9.271,9.271,0,0,1,1.47-.105,3.078,3.078,0,0,1,2.062.585A2.262,2.262,0,0,1,24.2,26.2a2.617,2.617,0,0,1-.788,2.01,3.376,3.376,0,0,1-2.287.69,10,10,0,0,1-1.245-.068Zm.923,4.282a3.457,3.457,0,0,0,.51.022,1.731,1.731,0,0,0,1.912-1.935,1.6,1.6,0,0,0-1.792-1.755,2.941,2.941,0,0,0-.63.053Z" transform="translate(-11.805 -14.107)" fill="#fff"/>
                    <path id="Caminho_1480" data-name="Caminho 1480" d="M37.517,26.2a2.39,2.39,0,0,1-2.423,2.663,2.336,2.336,0,0,1-2.325-2.573,2.42,2.42,0,0,1,2.4-2.648A2.335,2.335,0,0,1,37.517,26.2m-3.773.075c0,1.027.518,1.845,1.4,1.845s1.395-.825,1.395-1.882c0-.953-.472-1.853-1.395-1.853s-1.4.848-1.4,1.89" transform="translate(-19.458 -14.041)" fill="#fff"/>
                    <path id="Caminho_1481" data-name="Caminho 1481" d="M50.577,28.635a3.193,3.193,0,0,1-1.3.225,2.385,2.385,0,0,1-2.58-2.543,2.542,2.542,0,0,1,2.7-2.67,2.9,2.9,0,0,1,1.23.225l-.2.735a2.459,2.459,0,0,0-.99-.195,1.684,1.684,0,0,0-1.77,1.86,1.659,1.659,0,0,0,1.763,1.822,2.583,2.583,0,0,0,1-.187Z" transform="translate(-27.725 -14.041)" fill="#fff"/>
                </g>
                </g>
                <g id="Grupo_3778" data-name="Grupo 3778" transform="translate(2501.249 1280.151)">
                <path id="Caminho_1473-2" data-name="Caminho 1473" d="M.03,30.831A5.9,5.9,0,0,0,4.182,36.57a5.447,5.447,0,0,0,1.642.287c3.4.022,6.872.02,10.229.018h.135c2.924-.032,7.367-.086,7.775-.1a6.047,6.047,0,0,0,4.914-5.943.536.536,0,0,0-.518-.539.557.557,0,0,0-.6.515l-.006.072a6.315,6.315,0,0,1-.177,1.186,4.966,4.966,0,0,1-5,3.7c-4.791,0-10.474,0-16.291,0a5.793,5.793,0,0,1-1.5-.194,4.926,4.926,0,0,1-3.652-4.971c0-9.71,0-17.208,0-24.315a5.677,5.677,0,0,1,.194-1.5A4.921,4.921,0,0,1,6.3,1.11c5.891-.006,11.218,0,16.291,0a5.537,5.537,0,0,1,1.5.189,4.923,4.923,0,0,1,3.681,4.952q0,4.556,0,9.112v6.214c0,.691.413.691.548.691a.52.52,0,0,0,.4-.142.711.711,0,0,0,.167-.493V18.587c0-4.124.006-8.387-.006-12.579A5.9,5.9,0,0,0,24.9.366a9.65,9.65,0,0,0-.941-.272L23.888.077,23.589,0H5.418a1.619,1.619,0,0,1-.251.067,5.9,5.9,0,0,0-4.5,3.265A5.893,5.893,0,0,0,.009,6L0,6.417.024,9.229V19.264c0,3.827,0,7.723.006,11.568" fill="#fff"/>
                <g id="Grupo_3779" data-name="Grupo 3779" transform="translate(3.378 8.315)">
                    <g id="Grupo_3702" data-name="Grupo 3702">
                    <g id="Grupo_3701" data-name="Grupo 3701" transform="translate(0 0)" clipPath="url(#clip-path-3)">
                        <path id="Caminho_1452" data-name="Caminho 1452" d="M24.794,1.366A1.609,1.609,0,0,0,23.059.008l-14.82,0A2.452,2.452,0,0,0,7.757.04,1.6,1.6,0,0,0,6.466,1.668q0,5.626,0,11.232a1.959,1.959,0,0,0,.051.438,1.622,1.622,0,0,0,1.61,1.224H20.835q1.165,0,2.335,0a1.62,1.62,0,0,0,1.6-1.267c.013-.042.025-.074.036-.1l0-11.771ZM6.835,12.22l0-2.985c0-2.484,0-4.968-.011-7.452a1.231,1.231,0,0,1,0-.2A1.342,1.342,0,0,1,8.276.361c4.876.016,9.844.016,14.741,0a1.206,1.206,0,0,1,.206,0,1.347,1.347,0,0,1,1.23,1.45c-.012,1.974-.01,3.969-.008,5.963l0,3.238-.578-.636c-.06-.065-.1-.113-.144-.161q-1.915-2.24-3.836-4.477a1.1,1.1,0,0,0-1.391-.37,1.629,1.629,0,0,0-.492.407c-.842,1-1.673,2-2.5,3l-.923,1.109c-.036.047-.082.1-.14.164l-.247.279-.6-.6c-.3-.3-.6-.6-.89-.9l-.049-.049a1.168,1.168,0,0,0-1.647.046L9.26,10.567q-.856.853-1.707,1.71c-.023.03-.444.456-.457.456-.215,0-.262-.513-.262-.513m17.614.873a1.266,1.266,0,0,1-1.255,1.12,1.213,1.213,0,0,1-.145-.009l-14.911,0A1.314,1.314,0,0,1,7,13.588l.019-.011a.3.3,0,0,1,.1-.386l-.008-.016a1.061,1.061,0,0,0,.164-.109L11.2,9.137a.808.808,0,0,1,1.3,0l1.491,1.492c.144.144.2.162.237.153.018,0,.088-.015.22-.174l3.83-4.6a.911.911,0,0,1,.68-.387.842.842,0,0,1,.665.362q2.313,2.691,4.612,5.393a.972.972,0,0,1,.207.546c.015.219.013.442.009.665l0,.261a1.056,1.056,0,0,1,0,.246" transform="translate(-2.685 0)" fill="#fff"/>
                        <path id="Caminho_1453" data-name="Caminho 1453" d="M2.121,12.486a.184.184,0,0,0-.222.129c-.015.045-.024.079-.033.112q-.649,2.439-1.3,4.861C.427,18.1.286,18.6.147,19.108L0,19.637V20.1c.008.018.015.036.021.054a1.613,1.613,0,0,0,1.237,1.319l11.836,3.168q.963.259,1.925.521l.963.262.467,0a.723.723,0,0,1,.07-.027,1.612,1.612,0,0,0,1.251-1.176c.046-.154.083-.294.12-.434l.236-.875c.118-.433.236-.866.346-1.3a.509.509,0,0,0-.006-.273.116.116,0,0,0-.126-.063.193.193,0,0,0-.192.157l-.69,2.572a1.02,1.02,0,0,1-.085.305,1.255,1.255,0,0,1-1.646.658C10.969,23.7,6.173,22.417,1.379,21.13a1.069,1.069,0,0,1-.206-.056,1.251,1.251,0,0,1-.736-1.607l1.318-4.923c.164-.609.327-1.218.487-1.828a.181.181,0,0,0-.122-.232" transform="translate(0 -5.184)" fill="#fff"/>
                        <path id="Caminho_1454" data-name="Caminho 1454" d="M14.535,6.468a1.6,1.6,0,1,0-1.6,1.6,1.616,1.616,0,0,0,1.6-1.6m-1.6,1.241h0A1.236,1.236,0,0,1,11.7,6.483a1.233,1.233,0,0,1,1.225-1.24h.007a1.234,1.234,0,0,1,1.233,1.226v.006a1.235,1.235,0,0,1-1.235,1.234" transform="translate(-4.711 -2.024)" fill="#fff"/>
                    </g>
                    </g>
                </g>
                </g>
                <g id="Grupo_3780" data-name="Grupo 3780" transform="translate(2543.668 1280.151)">
                <path id="Caminho_1473-3" data-name="Caminho 1473" d="M.03,30.831A5.9,5.9,0,0,0,4.182,36.57a5.447,5.447,0,0,0,1.642.287c3.4.022,6.872.02,10.229.018h.135c2.924-.032,7.367-.086,7.775-.1a6.047,6.047,0,0,0,4.914-5.943.536.536,0,0,0-.518-.539.557.557,0,0,0-.6.515l-.006.072a6.315,6.315,0,0,1-.177,1.186,4.966,4.966,0,0,1-5,3.7c-4.791,0-10.474,0-16.291,0a5.793,5.793,0,0,1-1.5-.194,4.926,4.926,0,0,1-3.652-4.971c0-9.71,0-17.208,0-24.315a5.677,5.677,0,0,1,.194-1.5A4.921,4.921,0,0,1,6.3,1.11c5.891-.006,11.218,0,16.291,0a5.537,5.537,0,0,1,1.5.189,4.923,4.923,0,0,1,3.681,4.952q0,4.556,0,9.112v6.214c0,.691.413.691.548.691a.52.52,0,0,0,.4-.142.711.711,0,0,0,.167-.493V18.587c0-4.124.006-8.387-.006-12.579A5.9,5.9,0,0,0,24.9.366a9.65,9.65,0,0,0-.941-.272L23.888.077,23.589,0H5.418a1.619,1.619,0,0,1-.251.067,5.9,5.9,0,0,0-4.5,3.265A5.893,5.893,0,0,0,.009,6L0,6.417.024,9.229V19.264c0,3.827,0,7.723.006,11.568" fill="#fff"/>
                <g id="Grupo_3781" data-name="Grupo 3781" transform="translate(2.717 11.547)">
                    <g id="Grupo_3781-2" data-name="Grupo 3781" clipPath="url(#clip-path-4)">
                    <path id="Caminho_1482" data-name="Caminho 1482" d="M16.58,4.188a4.382,4.382,0,0,0-.062-.746A3.886,3.886,0,0,0,12.677.161q-1.078,0-2.156,0l-3.33,0H7.163c-1.09,0-2.181,0-3.271,0Q2.738.163,1.584.155H1.569A1.538,1.538,0,0,0,0,1.752C.03,4.274.008,6.8.011,9.318A3.906,3.906,0,0,0,3.893,13.3q2.4.006,4.795.005h1.719a.214.214,0,0,0,0-.429H8.688c-1.824,0-3.347,0-4.794-.005A3.48,3.48,0,0,1,.432,9.318c0-.8,0-1.621,0-2.411,0-1.691.007-3.439-.014-5.16A1.18,1.18,0,0,1,.74.915,1.123,1.123,0,0,1,1.569.584H1.58c.68.006,1.414.008,2.311.008l1.636,0,1.635,0h3.358c.831,0,1.516,0,2.155,0a3.455,3.455,0,0,1,3.367,2.644,3.731,3.731,0,0,1,.117.895c.01,2.378.008,4.8.006,7.135v.455a1.07,1.07,0,0,1-1.126,1.154H13.605a.214.214,0,0,0,0,.429h1.433a1.5,1.5,0,0,0,1.547-1.583c0-2.511.005-5.021,0-7.532" transform="translate(0.001 -0.144)" fill="#fff"/>
                    <path id="Caminho_1483" data-name="Caminho 1483" d="M254.389,6.6c0-1.783,0-3.565,0-5.348a1.179,1.179,0,0,0-.73-1.136A1.2,1.2,0,0,0,253.152,0a1.168,1.168,0,0,0-.784.329c-1.007.9-2.008,1.8-3.021,2.691a1.437,1.437,0,0,0-.507,1.149q0,2.42,0,4.84a1.424,1.424,0,0,0,.495,1.129c.26.23.525.454.779.691a.293.293,0,0,0,.027.023.209.209,0,0,0,.154.068.217.217,0,0,0,.11-.4l-.794-.7a.989.989,0,0,1-.35-.8c0-1.665,0-3.294,0-4.841a1.008,1.008,0,0,1,.361-.823c.7-.617,1.41-1.25,2.094-1.863l.93-.831a.757.757,0,0,1,.507-.223.8.8,0,0,1,.332.081.753.753,0,0,1,.482.747c0,1.272,0,2.566,0,3.817q0,.765,0,1.53,0,.746,0,1.493c0,1.22,0,2.482,0,3.723a.786.786,0,0,1-.471.831.8.8,0,0,1-.336.08.889.889,0,0,1-.59-.272l-.28-.252h0a.208.208,0,0,0-.15-.064.213.213,0,0,0-.211.215.216.216,0,0,0,.063.154,2.38,2.38,0,0,0,.3.269,1.3,1.3,0,0,0,.869.379,1.254,1.254,0,0,0,1.228-1.341c-.006-1.738,0-3.476,0-5.214" transform="translate(-231.396 0)" fill="#fff"/>
                    </g>
                </g>
                </g>
            </g>
        </svg>
    )
}

export default IconArquivos;