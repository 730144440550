import React from 'react';
import * as S from './styled';
import Ad from '../../assets/imagens/AD.png';
import Fetrafi from '../../assets/imagens/fetrafi.png';

const Footer = ({pagina}) => {
    return (
      <>
        <S.FundoRedDown>
          <S.Borda pagina={pagina}>
            {pagina === 7 ?
              ""  
            :
              <S.Esconde2>
                <img src={Fetrafi} alt="Fetrafi" width="70px" />
                <img src={Ad} alt="Alteranativa Digital" width="70px" />
              </S.Esconde2>
            }
          </S.Borda>
      </S.FundoRedDown>
      </>
    );
};

export default Footer;