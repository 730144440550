import React from 'react';
import * as S from './styled';
import LogoBancariosRS from '../../assets/imagens/logo_bancariosrs.png';

const HeaderFooter = ({ position }) => {
    return (
      <>
        <S.RedondoTop tamanho={position} />
        <S.DivText tamanho={position}>
          <S.Text>Um sistema do portal <img src={LogoBancariosRS} alt="Bancarios RS" style={{ marginLeft: "5px" }} /></S.Text>
        </S.DivText>
        <S.RedondoDown tamanho={position} />
      </>
    );
};

export default HeaderFooter;