import styled from 'styled-components';

export const DivText = styled.div`
    display: grid;
    justify-content: center;
    width: 100%;
    padding: ${({ tamanho }) => { if (tamanho === true) { return '30px 0 90px 0'; } else { return '90px 0 30px 0'; }}};
    background-color: #336AA7;
    margin-top: ${({ tamanho }) => { if (tamanho === false) { return '-60px'; } else { return '0'; }}};
`;

export const Text = styled.p`
    font-size: 12px;
    color: #ffffff;
`;

export const RedondoTop = styled.div`
    display: ${({ tamanho }) => { if (tamanho === false) { return 'flex'; } else { return 'none'; }}};
    height: 60px;
    width: 100%;
    background-color: #CE0F2A;
    border-radius: 0 0 30px 30px;
    position: relative;
    z-index: 4;
`;

export const RedondoDown = styled.div`
    display: ${({ tamanho }) => { if (tamanho === true) { return 'flex'; } else { return 'none'; }}};   
    height: 60px;
    width: 100%;
    background-color: #CE0F2A;
    border-radius: 30px 30px 0 0;
    margin-top: -60px;
`;
