import React, { useState, useEffect } from 'react';
import * as S from './styles';
import Pagina1 from '../../components/Pagina1';
// import Pagina2 from '../../components/Pagina2';
import Pagina3 from '../../components/Pagina3';
import Pagina4 from '../../components/Pagina4';
// import Pagina5 from '../../components/Pagina5';
// import Pagina6 from '../../components/Pagina6';
import Pagina7 from '../../components/Pagina7';
import HeaderFooter from '../../components/HeaderFooter';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Axios from '../../utils/Axios';

const Home = () => {
  const [acompanhada, setAcompanhada] = useState(false);
  const [pagina, setPagina] = useState(1);
  // dados usuario
  const [pais, setPais] = useState("");
  const [estado, setEstado] = useState("");
  const [cidade, setCidade] = useState("");
  const [empresaIp, setEmpresaIp] = useState("");
  // pagina 1
  // pagina 2
  // pagina 3
  const [motivo, setMotivo] = useState([]);
  // pagina 4
  const [nome, setNome] = useState("");
  const [nomeSocial, setNomeSocial] = useState("");
  const [celular, setCelular] = useState("");
  const [email, setEmail] = useState("");
  const [genero, setGenero] = useState("");
  const [raca, setRaca] = useState("");
  const [cargo, setCargo] = useState("");
  const [doenca, setDoenca] = useState("doencaNao");
  const [afastamento, setAfastamento] = useState("afastamentoNao");
  const [office, setOffice] = useState("officeNao");
  const [denunciado, setDenunciado] = useState("denunciaInstituicao");
  const [relato, setRelato] = useState("");
  const [nomeArquivo, setNomeArquivo] = useState([]);
  const [nomeArquivo2, setNomeArquivo2] = useState([]);
  const [nomeArquivo3, setNomeArquivo3] = useState([]);
  const [nomeArquivo4, setNomeArquivo4] = useState([]);
  const [nomeArquivo5, setNomeArquivo5] = useState([]);
  const [nomeArquivo6, setNomeArquivo6] = useState([]);
  const [nomeArquivo7, setNomeArquivo7] = useState([]);
  const [nomeArquivo8, setNomeArquivo8] = useState([]);
  const [nomeArquivo9, setNomeArquivo9] = useState([]);
  const [nomeArquivo10, setNomeArquivo10] = useState([]);
  const [picture, setPicture] = useState(null);
  const [imgData, setImgData] = useState(null);
  const [picture2, setPicture2] = useState(null);
  const [imgData2, setImgData2] = useState(null);
  const [picture3, setPicture3] = useState(null);
  const [imgData3, setImgData3] = useState(null);
  const [picture4, setPicture4] = useState(null);
  const [imgData4, setImgData4] = useState(null);
  const [picture5, setPicture5] = useState(null);
  const [imgData5, setImgData5] = useState(null);
  const [picture6, setPicture6] = useState(null);
  const [imgData6, setImgData6] = useState(null);
  const [picture7, setPicture7] = useState(null);
  const [imgData7, setImgData7] = useState(null);
  const [picture8, setPicture8] = useState(null);
  const [imgData8, setImgData8] = useState(null);
  const [picture9, setPicture9] = useState(null);
  const [imgData9, setImgData9] = useState(null);
  const [picture10, setPicture10] = useState(null);
  const [imgData10, setImgData10] = useState(null);
  const [termo, setTermo] = useState(false);
  const [banco, setBanco] = useState("");
  const [agencia, setAgencia] = useState("");
  const [cidade2, setCidade2] = useState("");
  // pagina 7
  const [protocolo, setProtocolo] = useState("");
  const [senha, setSenha] = useState("");
  

  const handleClick = (id) => {
    setPagina(id);
  };

  const handleSendForm = async () => {
    const formData = new FormData();
    formData.append("acompanhada", acompanhada ? "1" : "0");
    formData.append("nome", nome);
    formData.append("nomeSocial", nomeSocial);
    formData.append("celular", celular);
    formData.append("email", email);
    formData.append("genero", genero);
    formData.append("raca", raca);
    formData.append("cargo", cargo);
    formData.append("doenca", doenca);
    formData.append("afastamento", afastamento);
    formData.append("office", office);
    formData.append("motivo", motivo);
    formData.append("denunciado", denunciado);
    formData.append("relato", relato);
    formData.append("nomeArquivo", nomeArquivo);
    formData.append("nomeArquivo2", nomeArquivo2);
    formData.append("nomeArquivo3", nomeArquivo3);
    formData.append("nomeArquivo4", nomeArquivo4);
    formData.append("nomeArquivo5", nomeArquivo5);
    formData.append("nomeArquivo6", nomeArquivo6);
    formData.append("nomeArquivo7", nomeArquivo7);
    formData.append("nomeArquivo8", nomeArquivo8);
    formData.append("nomeArquivo9", nomeArquivo9);
    formData.append("nomeArquivo10", nomeArquivo10);
    formData.append("picture", picture);
    formData.append("picture2", picture2);
    formData.append("picture3", picture3);
    formData.append("picture4", picture4);
    formData.append("picture5", picture5);
    formData.append("picture6", picture6);
    formData.append("picture7", picture7);
    formData.append("picture8", picture8);
    formData.append("picture9", picture9);
    formData.append("picture10", picture10);
    formData.append("termo", termo === true ? "1" : "0");
    formData.append("pais", pais);
    formData.append("estado", estado);
    formData.append("cidade", cidade);
    formData.append("empresaIp", empresaIp);
    formData.append("banco", banco);
    formData.append("agencia", agencia);
    formData.append("cidade2", cidade2);
    
    const headers = {
      'headers': {
        'Content-Type': 'application/json'
      }
    }

    await Axios.post("/denuncia", formData, headers)
    .then((response) => {
      setProtocolo(response.data.protocolo);
      setSenha(response.data.senha);
      handleClick(7)
    })
  };

  const getIp = async () => {
    const ip = await Axios.get("https://ipapi.co/json")
    setPais(ip.data.country);
    setEstado(ip.data.region_code);
    setCidade(ip.data.city);
    setEmpresaIp(ip.data.org);
  }

  useEffect(() => {
    getIp();
  }, [])

  const BotaoVoltarAoTopo = () => {
    window.scrollTo(0, 0);
  }

  return (
    <div style={{ backgroundColor: "#336AA7" }}>
      <HeaderFooter position={true} />
      <Header pagina={pagina} />
      <S.Body>
        {pagina === 1 ?
          <Pagina1 handleClick={handleClick} BotaoVoltarAoTopo={BotaoVoltarAoTopo} />
        // : pagina === 2 ?
        //   <Pagina2 
        //     handleClick={handleClick} 
        //     nome={nome} setNome={setNome}
        //     nomeSocial={nomeSocial} setNomeSocial={setNomeSocial}
        //     celular={celular} setCelular={setCelular}
        //     email={email} setEmail={setEmail}
        //     genero={genero} setGenero={setGenero} 
        //     raca={raca} setRaca={setRaca}
        //     cargo={cargo} setCargo={setCargo}
        //     doenca={doenca} setDoenca={setDoenca}
        //     afastamento={afastamento} setAfastamento={setAfastamento}
        //     office={office} setOffice={setOffice}
        //     BotaoVoltarAoTopo={BotaoVoltarAoTopo}
        //   />
        : pagina === 3 ?
          <Pagina3 
            handleClick={handleClick}
            motivo={motivo} setMotivo={setMotivo}
            BotaoVoltarAoTopo={BotaoVoltarAoTopo}
          />
        : pagina === 4 ?
          <Pagina4 
            handleClick={handleClick} setAcompanhada={setAcompanhada} acompanhada={acompanhada}
            denunciado={denunciado} setDenunciado={setDenunciado}
            relato={relato} setRelato={setRelato}
            nomeArquivo={nomeArquivo} setNomeArquivo={setNomeArquivo}
            nomeArquivo2={nomeArquivo2} setNomeArquivo2={setNomeArquivo2}
            nomeArquivo3={nomeArquivo3} setNomeArquivo3={setNomeArquivo3}
            nomeArquivo4={nomeArquivo4} setNomeArquivo4={setNomeArquivo4}
            nomeArquivo5={nomeArquivo5} setNomeArquivo5={setNomeArquivo5}
            nomeArquivo6={nomeArquivo6} setNomeArquivo6={setNomeArquivo6}
            nomeArquivo7={nomeArquivo7} setNomeArquivo7={setNomeArquivo7}
            nomeArquivo8={nomeArquivo8} setNomeArquivo8={setNomeArquivo8}
            nomeArquivo9={nomeArquivo9} setNomeArquivo9={setNomeArquivo9}
            nomeArquivo10={nomeArquivo10} setNomeArquivo10={setNomeArquivo10}
            picture={picture} setPicture={setPicture}
            imgData={imgData} setImgData={setImgData}
            picture2={picture2} setPicture2={setPicture2}
            imgData2={imgData2} setImgData2={setImgData2}
            picture3={picture3} setPicture3={setPicture3}
            imgData3={imgData3} setImgData3={setImgData3}
            picture4={picture4} setPicture4={setPicture4}
            imgData4={imgData4} setImgData4={setImgData4}
            picture5={picture5} setPicture5={setPicture5}
            imgData5={imgData5} setImgData5={setImgData5}
            picture6={picture6} setPicture6={setPicture6}
            imgData6={imgData6} setImgData6={setImgData6}
            picture7={picture7} setPicture7={setPicture7}
            imgData7={imgData7} setImgData7={setImgData7}
            picture8={picture8} setPicture8={setPicture8}
            imgData8={imgData8} setImgData8={setImgData8}
            picture9={picture9} setPicture9={setPicture9}
            imgData9={imgData9} setImgData9={setImgData9}
            picture10={picture10} setPicture10={setPicture10}
            imgData10={imgData10} setImgData10={setImgData10}
            termo={termo} setTermo={setTermo} banco={banco}
            setBanco={setBanco} agencia={agencia} setAgencia={setAgencia}
            cidade2={cidade2} setCidade2={setCidade2} handleSendForm={handleSendForm}
            nome={nome} setNome={setNome} nomeSocial={nomeSocial} setNomeSocial={setNomeSocial}
            celular={celular} setCelular={setCelular}
            email={email} setEmail={setEmail}
            genero={genero} setGenero={setGenero} 
            raca={raca} setRaca={setRaca}
            cargo={cargo} setCargo={setCargo}
            doenca={doenca} setDoenca={setDoenca}
            afastamento={afastamento} setAfastamento={setAfastamento}
            office={office} setOffice={setOffice}
            BotaoVoltarAoTopo={BotaoVoltarAoTopo}
          />
        // : pagina === 5 ?
        //   <Pagina5 
        //     handleClick={handleClick}
        //     nome={nome} nomeSocial={nomeSocial}
        //     celular={celular} email={email}
        //     genero={genero} raca={raca}
        //     cargo={cargo} doenca={doenca}
        //     afastamento={afastamento} office={office}
        //     motivo={motivo} 
        //     denunciado={denunciado} relato={relato}
        //     nomeArquivo={nomeArquivo} nomeArquivo2={nomeArquivo2} 
        //     nomeArquivo3={nomeArquivo3} nomeArquivo4={nomeArquivo4} 
        //     nomeArquivo5={nomeArquivo5} nomeArquivo6={nomeArquivo6} 
        //     nomeArquivo7={nomeArquivo7} nomeArquivo8={nomeArquivo8} 
        //     nomeArquivo9={nomeArquivo9} nomeArquivo10={nomeArquivo10} 
        //     imgData={imgData} imgData2={imgData2}
        //     imgData3={imgData3} imgData4={imgData4}
        //     imgData5={imgData5} imgData6={imgData6}
        //     imgData7={imgData7} imgData8={imgData8}
        //     imgData9={imgData9} imgData10={imgData10}
        //     picture={picture} picture2={picture2}
        //     picture3={picture3} picture4={picture4}
        //     picture5={picture5} picture6={picture6}
        //     picture7={picture7} picture8={picture8}
        //     picture9={picture9} picture10={picture10}
        //     termo={termo} handleSendForm={handleSendForm}
        //     BotaoVoltarAoTopo={BotaoVoltarAoTopo}
        //   />
        // : pagina === 6 ?
        //   <Pagina6 />
        : pagina === 7 ?
          <Pagina7 
            protocolo={protocolo} setProtocolo={setProtocolo}
            senha={senha} setSenha={setSenha} 
            BotaoVoltarAoTopo={BotaoVoltarAoTopo}  
            email={email}
          />
        : 
          ""
        }
      </S.Body>
      <Footer pagina={pagina} />
      <HeaderFooter position={false} /> 
    </div>
  );
};

export default Home; 