import React from 'react';

function LogoTeste2(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="1002.722" height="353.166" viewBox="0 0 1002.722 353.166" {...props}>
            <g id="log0_cor" transform="translate(-5120.778 -3364.377)">
                <path id="Caminho_1605" data-name="Caminho 1605" d="M286.563,37.918a9.124,9.124,0,0,1,3.693-4.117,11.45,11.45,0,0,1,5.861-1.409,9.472,9.472,0,0,1,4.767,1.208,10.627,10.627,0,0,1,3.624,3.491,16.415,16.415,0,0,1,2.214,5.5h12.709a33.069,33.069,0,0,0-4.363-12.149,21.576,21.576,0,0,0-7.987-7.674,22.5,22.5,0,0,0-10.964-2.64,26.322,26.322,0,0,0-12.708,2.863,19.081,19.081,0,0,0-8.055,8.3,28.61,28.61,0,0,0-2.774,13.133V62.283a28.608,28.608,0,0,0,2.774,13.134,18.943,18.943,0,0,0,8.055,8.278,26.47,26.47,0,0,0,12.708,2.842,22.364,22.364,0,0,0,10.941-2.64,21.643,21.643,0,0,0,7.965-7.675,33.641,33.641,0,0,0,4.408-12.149H306.722a16.087,16.087,0,0,1-2.147,5.549,10.154,10.154,0,0,1-3.625,3.468,9.826,9.826,0,0,1-4.833,1.186,11.44,11.44,0,0,1-5.861-1.41,9.145,9.145,0,0,1-3.693-4.095,15.037,15.037,0,0,1-1.275-6.488V44.428a15.193,15.193,0,0,1,1.275-6.51" transform="translate(5120.778 3364.377)" stroke="rgba(0,0,0,0)" stroke-miterlimit="10" stroke-width="1"/>
                <path id="Caminho_1606" data-name="Caminho 1606" d="M346.549,20.8l-24.88,65.064h13.2l3.655-10.427h23.837l3.654,10.427h13.2L354.335,20.8Zm-3.885,42.824,7.779-22.195,7.778,22.195Z" transform="translate(5120.778 3364.377)" stroke="rgba(0,0,0,0)" stroke-miterlimit="10" stroke-width="1"/>
                <path id="Caminho_1607" data-name="Caminho 1607" d="M425.4,68.441,402.261,20.8H387.27V85.821h11.724v-46.9l23.046,46.9h15.08V20.8H425.4Z" transform="translate(5120.778 3364.377)" stroke="rgba(0,0,0,0)" stroke-miterlimit="10" stroke-width="1"/>
                <path id="Caminho_1608" data-name="Caminho 1608" d="M469.16,20.8,444.28,85.865h13.2l3.654-10.427h23.836l3.654,10.427h13.2L476.947,20.8Zm-3.885,42.824,7.778-22.195,7.778,22.195Z" transform="translate(5120.778 3364.377)" stroke="rgba(0,0,0,0)" stroke-miterlimit="10" stroke-width="1"/>
                <path id="Caminho_1609" data-name="Caminho 1609" d="M522.142,20.8H509.881V85.864h43.585V74.051H522.142Z" transform="translate(5120.778 3364.377)" stroke="rgba(0,0,0,0)" stroke-miterlimit="10" stroke-width="1"/>
                <path id="Caminho_1610" data-name="Caminho 1610" d="M657.054,23.531A32.2,32.2,0,0,0,643.16,20.8H621.635V85.865h21.48a31.181,31.181,0,0,0,14.139-2.908,19.689,19.689,0,0,0,8.66-8.144A25,25,0,0,0,668.8,62.552V44.115a25,25,0,0,0-3-12.618,19.346,19.346,0,0,0-8.747-7.966m-.963,39.378q0,5.326-3.31,8.234t-9.353,2.909H633.9V32.615h9.532q6.041,0,9.353,2.909t3.31,8.234Z" transform="translate(5120.778 3364.377)" stroke="rgba(0,0,0,0)" stroke-miterlimit="10" stroke-width="1"/>
                <path id="Caminho_1611" data-name="Caminho 1611" d="M724.466,32.614V20.8H680.881V85.864h43.585V74.051H693.142V59.463h26.4V47.65h-26.4V32.614Z" transform="translate(5120.778 3364.377)" stroke="rgba(0,0,0,0)" stroke-miterlimit="10" stroke-width="1"/>
                <path id="Caminho_1612" data-name="Caminho 1612" d="M328,104.776q-8.668-4.113-20.946-4.113H274.6v98.085h32.381q12.613,0,21.318-4.385a29.687,29.687,0,0,0,13.053-12.277,37.684,37.684,0,0,0,4.351-18.484V135.809q0-11.132-4.52-19.024A29.179,29.179,0,0,0,328,104.776m-1.451,59.365q0,8.029-4.991,12.413t-14.1,4.385H293.087V118.472h14.369q9.108,0,14.1,4.384t4.991,12.414Z" transform="translate(5120.778 3364.377)" stroke="rgba(0,0,0,0)" stroke-miterlimit="10" stroke-width="1"/>
                <path id="Caminho_1613" data-name="Caminho 1613" d="M370.6,100.663H363.92v98.085h65.705V180.939H382.4V158.947h39.8V141.138H382.4V118.472h47.222V100.663H370.6Z" transform="translate(5120.778 3364.377)" stroke="rgba(0,0,0,0)" stroke-miterlimit="10" stroke-width="1"/>
                <path id="Caminho_1614" data-name="Caminho 1614" d="M503.966,172.477,469.09,100.662h-22.6V198.68h17.675V127.972l34.741,70.708h22.733V100.662H503.966Z" transform="translate(5120.778 3364.377)" stroke="rgba(0,0,0,0)" stroke-miterlimit="10" stroke-width="1"/>
                <path id="Caminho_1615" data-name="Caminho 1615" d="M595.038,163.736q0,8.435-4.556,13.087t-12.849,4.654q-8.232,0-12.75-4.654t-4.52-13.087V100.662H541.878v62.6q0,11.535,4.183,19.732a28.464,28.464,0,0,0,12.211,12.479q8.025,4.285,19.361,4.284t19.394-4.284A28.6,28.6,0,0,0,609.3,183q4.218-8.2,4.218-19.732v-62.6H595.038Z" transform="translate(5120.778 3364.377)" stroke="rgba(0,0,0,0)" stroke-miterlimit="10" stroke-width="1"/>
                <path id="Caminho_1616" data-name="Caminho 1616" d="M600.165,79.344l-12.28-12.615L569.268,83.257,580.263,94.59Z" transform="translate(5120.778 3364.377)" stroke="rgba(0,0,0,0)" stroke-miterlimit="10" stroke-width="1"/>
                <path id="Caminho_1617" data-name="Caminho 1617" d="M689.885,172.477l-34.877-71.815h-22.6V198.68h17.675V127.974l34.742,70.706h22.732V100.662H689.885Z" transform="translate(5120.778 3364.377)" stroke="rgba(0,0,0,0)" stroke-miterlimit="10" stroke-width="1"/>
                <path id="Caminho_1618" data-name="Caminho 1618" d="M748.2,126.465a13.751,13.751,0,0,1,5.564-6.2,17.259,17.259,0,0,1,8.837-2.125,14.285,14.285,0,0,1,7.186,1.82,16.044,16.044,0,0,1,5.465,5.263,24.807,24.807,0,0,1,3.338,8.3h19.159a49.916,49.916,0,0,0-6.577-18.316,32.562,32.562,0,0,0-12.042-11.568A33.9,33.9,0,0,0,762.6,99.65q-11.2,0-19.158,4.318A28.768,28.768,0,0,0,731.3,116.481q-4.185,8.2-4.182,19.8V163.2q0,11.6,4.182,19.8a28.572,28.572,0,0,0,12.142,12.479q7.961,4.284,19.158,4.284a33.7,33.7,0,0,0,16.494-3.98,32.635,32.635,0,0,0,12.009-11.571,50.739,50.739,0,0,0,6.645-18.313H778.594a24.239,24.239,0,0,1-3.238,8.364,15.3,15.3,0,0,1-5.464,5.228,14.822,14.822,0,0,1-7.287,1.788,17.268,17.268,0,0,1-8.837-2.125,13.783,13.783,0,0,1-5.564-6.173,22.642,22.642,0,0,1-1.923-9.781V136.28a22.879,22.879,0,0,1,1.923-9.815" transform="translate(5120.778 3364.377)" stroke="rgba(0,0,0,0)" stroke-miterlimit="10" stroke-width="1"/>
                <rect id="Retângulo_3024" data-name="Retângulo 3024" width="19.158" height="98.085" transform="translate(5932.698 3465.039)" stroke="rgba(0,0,0,0)" stroke-miterlimit="10" stroke-width="1"/>
                <path id="Caminho_1619" data-name="Caminho 1619" d="M878.03,100.663l-37.508,98.085h19.9l5.509-15.718h35.934l5.508,15.718h19.9l-37.508-98.085Zm-5.857,64.558,11.726-33.46,11.726,33.46Z" transform="translate(5120.778 3364.377)" stroke="rgba(0,0,0,0)" stroke-miterlimit="10" stroke-width="1"/>
                <path id="Caminho_1620" data-name="Caminho 1620" d="M998.04,154.123a24.229,24.229,0,0,0-10.423-8.365,82.127,82.127,0,0,0-15.549-4.417,7.816,7.816,0,0,1-.775-.17q-.372-.1-.776-.169a4.936,4.936,0,0,0-.541-.1,4.405,4.405,0,0,1-.539-.1,94.923,94.923,0,0,1-10.051-2.428,14.467,14.467,0,0,1-6-3.609,9.144,9.144,0,0,1-2.429-6.712v-.069a8.73,8.73,0,0,1,4.654-7.858q4.655-2.8,13.155-2.8a35.841,35.841,0,0,1,11.84,2.16,49.63,49.63,0,0,1,11.839,6.07l7.825-15.65a60.074,60.074,0,0,0-9.985-5.532,58.393,58.393,0,0,0-10.76-3.508,49.767,49.767,0,0,0-10.759-1.214q-11.469,0-19.563,3.542a26.825,26.825,0,0,0-12.346,10.286,29.893,29.893,0,0,0-4.25,16.26v.067q0,10.725,4.521,16.561a24.035,24.035,0,0,0,10.995,8.162,91,91,0,0,0,16.325,3.878l1.485.2a1.177,1.177,0,0,0,.371.067,1.162,1.162,0,0,1,.371.068,70.137,70.137,0,0,1,9.175,1.956,13.221,13.221,0,0,1,5.8,3.372,8.535,8.535,0,0,1,2.227,6.208v.067a9.648,9.648,0,0,1-4.992,8.668q-4.992,3.069-14.03,3.07a46.956,46.956,0,0,1-15.248-2.531,41.487,41.487,0,0,1-13.086-7.184l-8.568,15.245a48.355,48.355,0,0,0,10.558,6.578,58.762,58.762,0,0,0,12.615,4.149,66.393,66.393,0,0,0,13.729,1.417q11.8,0,20.2-3.509a27.4,27.4,0,0,0,12.783-10.186,28.6,28.6,0,0,0,4.385-16.055v-.135q0-9.982-4.182-15.752" transform="translate(5120.778 3364.377)" stroke="rgba(0,0,0,0)" stroke-miterlimit="10" stroke-width="1"/>
                <path id="Caminho_1621" data-name="Caminho 1621" d="M276.673,218.243V351.878h25.5V297.812H369.5v-25.5H302.175V243.746h76v-25.5Z" transform="translate(5120.778 3364.377)" stroke="rgba(0,0,0,0)" stroke-miterlimit="10" stroke-width="1" fill-rule="evenodd"/>
                <path id="Caminho_1622" data-name="Caminho 1622" d="M809.424,214.772H781.752l.413.942-58.94,136.929,26.069-.767,14.317-32.644h63.955l14.317,32.644,26.067.767L809.011,215.714Zm6.957,78.958H774.8l20.792-47.41Z" transform="translate(5120.778 3364.377)" stroke="rgba(0,0,0,0)" stroke-miterlimit="10" stroke-width="1" fill-rule="evenodd"/>
                <path id="Caminho_1623" data-name="Caminho 1623" d="M385.825,218.243V351.878h101.5v-25.5h-76V297.812h59.166v-25.5H411.326V243.746h76v-25.5Z" transform="translate(5120.778 3364.377)" stroke="rgba(0,0,0,0)" stroke-miterlimit="10" stroke-width="1" fill-rule="evenodd"/>
                <path id="Caminho_1624" data-name="Caminho 1624" d="M492.426,243.746H532.72V351.878h25.5V243.746h39.785v-25.5H492.426Z" transform="translate(5120.778 3364.377)" stroke="rgba(0,0,0,0)" stroke-miterlimit="10" stroke-width="1" fill-rule="evenodd"/>
                <rect id="Retângulo_3025" data-name="Retângulo 3025" width="25.501" height="133.635" transform="translate(6097.499 3582.619)" stroke="rgba(0,0,0,0)" stroke-miterlimit="10" stroke-width="1"/>
                <path id="Caminho_1625" data-name="Caminho 1625" d="M871.14,218.243V351.878h25.5V297.812h67.325v-25.5H896.643V243.746h76v-25.5Z" transform="translate(5120.778 3364.377)" stroke="rgba(0,0,0,0)" stroke-miterlimit="10" stroke-width="1" fill-rule="evenodd"/>
                <path id="Caminho_1626" data-name="Caminho 1626" d="M692.781,297.653c5.738-2.87,21.327-11.668,23.715-21.424s4.305-32.227-5.26-42.843-20.4-15.143-39.272-15.143H607.189V351.878h25.5V306.484h29.155l28.02,45.394h31.065l-35.676-51.654a38.793,38.793,0,0,0,7.526-2.571m-60.09-53.907h39.274c.02,0,11.351-.7,13.452,4.751s4.348,16.64,2.412,23.43-11.337,8.637-15.865,9.053H632.691Z" transform="translate(5120.778 3364.377)" stroke="rgba(0,0,0,0)" stroke-miterlimit="10" stroke-width="1" fill-rule="evenodd"/>
                <path id="Caminho_1627" data-name="Caminho 1627" d="M206.387,60.65A18.6,18.6,0,0,0,187.85,79.19v63.857a3.572,3.572,0,0,1-3.572,3.571h-.006a3.571,3.571,0,0,1-3.572-3.571V39.927a18.547,18.547,0,0,0-37.093,0v103.12a3.571,3.571,0,0,1-3.572,3.571h-.006a3.571,3.571,0,0,1-3.572-3.571V18.543a18.543,18.543,0,0,0-37.087,0v124.5a3.572,3.572,0,0,1-7.143,0V39.934a18.546,18.546,0,1,0-37.093,0V174.48c8.312.641,28.548,4.707,47.526,27.861,19.417,23.687,19.452,59.151,19.444,60.651a4.456,4.456,0,0,1-4.457,4.432h-.022a4.46,4.46,0,0,1-4.437-4.478c0-.334-.072-33.785-17.424-54.953-21.055-25.687-43.013-24.762-43.924-24.7-.106.006-.208-.012-.313-.013-.014,0-.027.006-.04.006l-4.371-.177L40.3,116.4A20.2,20.2,0,1,0,.1,120.36l13.66,122.827c0,40.151,17.637,77.756,53.208,96.38a107.916,107.916,0,0,0,157.969-95.6V79.19a18.613,18.613,0,0,0-18.549-18.54" transform="translate(5120.778 3364.377)" fill="#be1622" fill-rule="evenodd"/>
                <path id="Caminho_1628" data-name="Caminho 1628" d="M958.2,352.448a26.16,26.16,0,0,1-8.858-1.552,23.453,23.453,0,0,1-7.9-4.767l5.134-6.151a25.8,25.8,0,0,0,5.558,3.5,14.854,14.854,0,0,0,6.235,1.3,7.593,7.593,0,0,0,4.2-.988,3.107,3.107,0,0,0,1.5-2.737v-.113a3.437,3.437,0,0,0-.309-1.5,3.142,3.142,0,0,0-1.186-1.212,12.934,12.934,0,0,0-2.435-1.13c-1.035-.375-2.388-.77-4.044-1.183a49.54,49.54,0,0,1-5.436-1.637,15.373,15.373,0,0,1-4.129-2.228,9.074,9.074,0,0,1-2.633-3.3,11.492,11.492,0,0,1-.931-4.911v-.112a11.508,11.508,0,0,1,1.008-4.881,11.156,11.156,0,0,1,2.83-3.752,12.722,12.722,0,0,1,4.354-2.426,17.454,17.454,0,0,1,5.575-.847,23.624,23.624,0,0,1,7.986,1.3,23.208,23.208,0,0,1,6.686,3.723l-4.514,6.546a28.623,28.623,0,0,0-5.191-2.849,13.251,13.251,0,0,0-5.079-1.043,6.15,6.15,0,0,0-3.809.986,3,3,0,0,0-1.269,2.454v.114a3.586,3.586,0,0,0,.366,1.665,3.33,3.33,0,0,0,1.326,1.268,12.866,12.866,0,0,0,2.634,1.073q1.677.507,4.213,1.185a40.064,40.064,0,0,1,5.352,1.777,14.973,14.973,0,0,1,3.959,2.37,9.05,9.05,0,0,1,2.434,3.244,11.029,11.029,0,0,1,.819,4.458v.114a12.288,12.288,0,0,1-1.063,5.219,10.768,10.768,0,0,1-2.972,3.836,13.424,13.424,0,0,1-4.55,2.371,19.8,19.8,0,0,1-5.86.817m-54.9-40.062H921.35q7.5,0,11.511,4.006a12.206,12.206,0,0,1,3.386,9.03v.112a12.37,12.37,0,0,1-2.342,7.813,13.563,13.563,0,0,1-6.123,4.431l9.649,14.106H927.275l-8.464-12.64h-6.828v12.64h-8.688Zm17.49,19.185a7.215,7.215,0,0,0,4.938-1.524,5.16,5.16,0,0,0,1.722-4.063v-.112a5.039,5.039,0,0,0-1.806-4.232,7.973,7.973,0,0,0-5.022-1.411h-8.634v11.342Z" transform="translate(5120.778 3364.377)" fill="#e30613"/>
            </g>
        </svg>
    )
}

export default LogoTeste2;