import React from 'react';
import * as S from './styled';
// import ButtonPrimary from '../ButtonPrimary';
// import LogoCanal from '../../assets/imagens/canal.png';
import LogoTeste from '../../assets/svg/LogoTeste';

const Pagina1 = ({ handleClick, BotaoVoltarAoTopo }) => {
    return (
        <S.Base>
            <S.Conteudo>
                <S.DivEncaixe>
                    <S.DivLogo>
                        <LogoTeste />
                    </S.DivLogo>
                    <S.DivTeste onClick={() => {handleClick(3); BotaoVoltarAoTopo()}}>
                        <S.Text>Denunciar</S.Text>
                    </S.DivTeste>
                    <S.TextLightLink href="http://minhadenuncia.bancariosrs.com.br/" target="_blank">
                        <S.DivButtonClick>
                            Acessar denúncia já realizada
                        </S.DivButtonClick>
                    </S.TextLightLink>
                </S.DivEncaixe>
            </S.Conteudo>
        </S.Base>
    );
};

export default Pagina1;