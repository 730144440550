import React from 'react';

function LogoTeste(props) {
    return (
        <svg id="Grupo_4397" data-name="Grupo 4397" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="483.586" height="170.155" viewBox="0 0 483.586 170.155" fill="none" {...props}>
            <defs>
                <clipPath id="clip-path">
                <rect id="Retângulo_3026" data-name="Retângulo 3026" width="483.586" height="170.155" fill="#fff"/>
                </clipPath>
            </defs>
            <g id="Grupo_4390" data-name="Grupo 4390" clip-path="url(#clip-path)">
                <path id="Caminho_1605" data-name="Caminho 1605" d="M279.327,28.713a4.4,4.4,0,0,1,1.782-1.987,5.525,5.525,0,0,1,2.828-.68,4.57,4.57,0,0,1,2.3.583,5.128,5.128,0,0,1,1.749,1.684,7.922,7.922,0,0,1,1.068,2.655h6.132a15.956,15.956,0,0,0-2.105-5.862,10.411,10.411,0,0,0-3.854-3.7,10.854,10.854,0,0,0-5.29-1.274,12.7,12.7,0,0,0-6.132,1.381,9.207,9.207,0,0,0-3.887,4.005,13.8,13.8,0,0,0-1.338,6.337V40.47a13.8,13.8,0,0,0,1.338,6.337,9.14,9.14,0,0,0,3.887,3.994,12.772,12.772,0,0,0,6.132,1.371,10.791,10.791,0,0,0,5.279-1.274,10.443,10.443,0,0,0,3.843-3.7,16.232,16.232,0,0,0,2.127-5.862h-6.132a7.763,7.763,0,0,1-1.036,2.677,4.9,4.9,0,0,1-1.749,1.673,4.741,4.741,0,0,1-2.332.572,5.52,5.52,0,0,1-2.828-.68,4.413,4.413,0,0,1-1.782-1.976,7.255,7.255,0,0,1-.615-3.131V31.855a7.331,7.331,0,0,1,.615-3.141" transform="translate(-141.056 -10.418)" fill="#fff"/>
                <path id="Caminho_1606" data-name="Caminho 1606" d="M333.674,20.8l-12,31.394h6.369l1.764-5.031h11.5l1.763,5.031h6.369l-12-31.394ZM331.8,41.464l3.753-10.709,3.753,10.709Z" transform="translate(-166.459 -10.764)" fill="#fff"/>
                <path id="Caminho_1607" data-name="Caminho 1607" d="M405.666,43.789,394.5,20.8H387.27V52.175h5.657V29.543l11.12,22.632h7.276V20.8h-5.657Z" transform="translate(-200.407 -10.765)" fill="#fff"/>
                <path id="Caminho_1608" data-name="Caminho 1608" d="M456.285,20.8l-12,31.394h6.37l1.763-5.031h11.5l1.763,5.031h6.37l-12-31.394ZM454.41,41.464l3.753-10.709,3.753,10.709Z" transform="translate(-229.909 -10.764)" fill="#fff"/>
                <path id="Caminho_1609" data-name="Caminho 1609" d="M515.8,20.8h-5.916V52.2h21.03V46.5H515.8Z" transform="translate(-263.856 -10.764)" fill="#fff"/>
                <path id="Caminho_1610" data-name="Caminho 1610" d="M638.725,22.119a15.539,15.539,0,0,0-6.7-1.317H621.635V52.2H632a15.045,15.045,0,0,0,6.822-1.4A9.5,9.5,0,0,0,643,46.863a12.061,12.061,0,0,0,1.392-5.916v-8.9a12.06,12.06,0,0,0-1.447-6.088,9.334,9.334,0,0,0-4.22-3.844m-.465,19a5.007,5.007,0,0,1-1.6,3.973,6.618,6.618,0,0,1-4.513,1.4h-4.6V26.5h4.6a6.621,6.621,0,0,1,4.513,1.4,5.009,5.009,0,0,1,1.6,3.973Z" transform="translate(-321.688 -10.765)" fill="#fff"/>
                <path id="Caminho_1611" data-name="Caminho 1611" d="M701.911,26.5V20.8h-21.03V52.2h21.03V46.5H686.8V39.456h12.739v-5.7H686.8V26.5Z" transform="translate(-352.346 -10.764)" fill="#fff"/>
                <path id="Caminho_1612" data-name="Caminho 1612" d="M300.367,102.648a23.427,23.427,0,0,0-10.107-1.985H274.6V147.99h15.624a22.685,22.685,0,0,0,10.286-2.116,14.324,14.324,0,0,0,6.3-5.924,18.183,18.183,0,0,0,2.1-8.919v-13.41a18.185,18.185,0,0,0-2.181-9.179,14.079,14.079,0,0,0-6.363-5.795m-.7,28.644a7.549,7.549,0,0,1-2.408,5.989,9.979,9.979,0,0,1-6.8,2.116h-6.933V109.256h6.933a9.983,9.983,0,0,1,6.8,2.115,7.552,7.552,0,0,1,2.408,5.99Z" transform="translate(-142.103 -52.092)" fill="#fff"/>
                <path id="Caminho_1613" data-name="Caminho 1613" d="M367.143,100.663H363.92V147.99h31.7V139.4H372.838V128.785h19.205v-8.593H372.838V109.256h22.785v-8.593H367.143Z" transform="translate(-188.323 -52.091)" fill="#fff"/>
                <path id="Caminho_1614" data-name="Caminho 1614" d="M474.223,135.314,457.4,100.662h-10.9v47.3h8.528V113.84l16.763,34.118h10.969v-47.3h-8.527Z" transform="translate(-231.053 -52.091)" fill="#fff"/>
                <path id="Caminho_1615" data-name="Caminho 1615" d="M567.528,131.1a8.646,8.646,0,0,1-2.2,6.315,8.275,8.275,0,0,1-6.2,2.246,8.178,8.178,0,0,1-6.152-2.246A8.683,8.683,0,0,1,550.8,131.1V100.662h-8.919v30.206a20.733,20.733,0,0,0,2.018,9.521,13.734,13.734,0,0,0,5.892,6.021,22.179,22.179,0,0,0,18.7,0,13.8,13.8,0,0,0,5.923-6.021,20.59,20.59,0,0,0,2.035-9.521V100.662h-8.918Z" transform="translate(-280.414 -52.091)" fill="#fff"/>
                <path id="Caminho_1616" data-name="Caminho 1616" d="M584.176,72.816l-5.925-6.087L569.268,74.7l5.305,5.468Z" transform="translate(-294.588 -34.532)" fill="#fff"/>
                <path id="Caminho_1617" data-name="Caminho 1617" d="M660.142,135.314l-16.829-34.652h-10.9v47.3h8.528V113.841L657.7,147.957H668.67v-47.3h-8.527Z" transform="translate(-327.263 -52.091)" fill="#fff"/>
                <path id="Caminho_1618" data-name="Caminho 1618" d="M737.295,112.589a6.635,6.635,0,0,1,2.685-2.994,8.328,8.328,0,0,1,4.264-1.025,6.893,6.893,0,0,1,3.467.878,7.741,7.741,0,0,1,2.637,2.539,11.97,11.97,0,0,1,1.611,4H761.2a24.085,24.085,0,0,0-3.173-8.838,15.711,15.711,0,0,0-5.81-5.582,16.359,16.359,0,0,0-7.975-1.921A19.138,19.138,0,0,0,735,101.734a13.881,13.881,0,0,0-5.859,6.038,20.805,20.805,0,0,0-2.018,9.553v12.988a20.8,20.8,0,0,0,2.018,9.554A13.787,13.787,0,0,0,735,145.887a19.259,19.259,0,0,0,9.244,2.067,16.262,16.262,0,0,0,7.958-1.92A15.746,15.746,0,0,0,758,140.451a24.482,24.482,0,0,0,3.206-8.836h-9.244a11.7,11.7,0,0,1-1.562,4.036,7.383,7.383,0,0,1-2.636,2.523,7.152,7.152,0,0,1-3.516.863,8.333,8.333,0,0,1-4.264-1.025,6.651,6.651,0,0,1-2.685-2.979,10.926,10.926,0,0,1-.928-4.719V117.324a11.04,11.04,0,0,1,.928-4.736" transform="translate(-376.276 -51.568)" fill="#fff"/>
                <rect id="Retângulo_3024" data-name="Retângulo 3024" width="9.244" height="47.327" transform="translate(391.763 48.571)" fill="#fff"/>
                <path id="Caminho_1619" data-name="Caminho 1619" d="M858.62,100.663l-18.1,47.327h9.6l2.658-7.584h17.339l2.658,7.584h9.6l-18.1-47.327Zm-2.826,31.15,5.658-16.145,5.658,16.145Z" transform="translate(-434.959 -52.092)" fill="#fff"/>
                <path id="Caminho_1620" data-name="Caminho 1620" d="M961.769,125.934a11.692,11.692,0,0,0-5.029-4.036,39.629,39.629,0,0,0-7.5-2.131,3.773,3.773,0,0,1-.374-.082q-.179-.048-.374-.082a2.376,2.376,0,0,0-.261-.048,2.127,2.127,0,0,1-.26-.049,45.807,45.807,0,0,1-4.85-1.172,6.981,6.981,0,0,1-2.9-1.741,4.413,4.413,0,0,1-1.172-3.239v-.033a4.212,4.212,0,0,1,2.246-3.792,12.381,12.381,0,0,1,6.347-1.351,17.294,17.294,0,0,1,5.713,1.042,23.948,23.948,0,0,1,5.712,2.929l3.776-7.551a28.984,28.984,0,0,0-4.818-2.669,28.175,28.175,0,0,0-5.192-1.693,24.014,24.014,0,0,0-5.191-.586,23.474,23.474,0,0,0-9.439,1.709,12.945,12.945,0,0,0-5.957,4.963,14.424,14.424,0,0,0-2.051,7.846v.032q0,5.175,2.181,7.991a11.6,11.6,0,0,0,5.305,3.938A43.9,43.9,0,0,0,945.558,128l.716.1a.568.568,0,0,0,.179.032.561.561,0,0,1,.179.033,33.844,33.844,0,0,1,4.427.944,6.38,6.38,0,0,1,2.8,1.627,4.118,4.118,0,0,1,1.075,3v.032a4.655,4.655,0,0,1-2.409,4.182,12.952,12.952,0,0,1-6.77,1.481,22.657,22.657,0,0,1-7.357-1.221,20.017,20.017,0,0,1-6.314-3.466l-4.134,7.356a23.329,23.329,0,0,0,5.094,3.174,28.352,28.352,0,0,0,6.087,2,32.036,32.036,0,0,0,6.624.684,25.24,25.24,0,0,0,9.748-1.693,13.22,13.22,0,0,0,6.168-4.915,13.8,13.8,0,0,0,2.116-7.747v-.065a12.717,12.717,0,0,0-2.018-7.6" transform="translate(-480.201 -51.568)" fill="#fff"/>
                <path id="Caminho_1621" data-name="Caminho 1621" d="M276.673,218.243v64.481h12.305V256.636h32.487V244.331H288.978V230.548h36.67V218.243Z" transform="translate(-143.174 -112.938)" fill="#fff" fill-rule="evenodd"/>
                <path id="Caminho_1622" data-name="Caminho 1622" d="M764.817,214.772H751.465l.2.455L723.225,281.3l12.579-.37,6.908-15.751h30.859l6.908,15.751,12.578.37-28.439-66.07Zm3.357,38.1H748.109l10.032-22.876Z" transform="translate(-374.259 -111.142)" fill="#fff" fill-rule="evenodd"/>
                <path id="Caminho_1623" data-name="Caminho 1623" d="M385.825,218.243v64.481H434.8V270.418h-36.67V256.636h28.548V244.331H398.129V230.548H434.8V218.243Z" transform="translate(-199.659 -112.938)" fill="#fff" fill-rule="evenodd"/>
                <path id="Caminho_1624" data-name="Caminho 1624" d="M492.426,230.548h19.442v52.175h12.305V230.548h19.2V218.243H492.426Z" transform="translate(-254.824 -112.938)" fill="#fff" fill-rule="evenodd"/>
                <rect id="Retângulo_3025" data-name="Retângulo 3025" width="12.305" height="64.481" transform="translate(471.281 105.305)" fill="#fff"/>
                <path id="Caminho_1625" data-name="Caminho 1625" d="M871.14,218.243v64.481h12.306V256.636h32.485V244.331H883.445V230.548h36.671V218.243Z" transform="translate(-450.803 -112.938)" fill="#fff" fill-rule="evenodd"/>
                <path id="Caminho_1626" data-name="Caminho 1626" d="M648.488,256.559c2.769-1.385,10.291-5.63,11.443-10.337s2.077-15.55-2.538-20.672-9.842-7.307-18.949-7.307H607.189v64.481h12.305v-21.9h14.068l13.52,21.9h14.989L644.857,257.8a18.719,18.719,0,0,0,3.631-1.241m-28.994-26.011h18.95c.01,0,5.477-.336,6.491,2.292s2.1,8.029,1.164,11.305-5.47,4.167-7.655,4.368h-18.95Z" transform="translate(-314.212 -112.938)" fill="#fff" fill-rule="evenodd"/>
                <path id="Caminho_1627" data-name="Caminho 1627" d="M99.585,29.264A8.973,8.973,0,0,0,90.64,38.21V69.022a1.724,1.724,0,0,1-1.724,1.723h0a1.723,1.723,0,0,1-1.724-1.723V19.265a8.949,8.949,0,0,0-17.9,0V69.022a1.723,1.723,0,0,1-1.724,1.723h0a1.723,1.723,0,0,1-1.724-1.723V8.947a8.947,8.947,0,0,0-17.895,0V69.022a1.723,1.723,0,0,1-3.447,0V19.269a8.949,8.949,0,1,0-17.9,0v64.92c4.011.309,13.775,2.271,22.932,13.443,9.369,11.429,9.386,28.541,9.382,29.265a2.15,2.15,0,0,1-2.151,2.139h-.011a2.152,2.152,0,0,1-2.141-2.161c0-.161-.035-16.3-8.407-26.516C36.048,87.965,25.453,88.411,25.014,88.443c-.051,0-.1-.006-.151-.006-.007,0-.013,0-.019,0l-2.109-.085-3.287-32.19a9.747,9.747,0,1,0-19.4,1.911l6.591,59.266c0,19.373,8.51,37.518,25.674,46.5a52.071,52.071,0,0,0,76.222-46.131V38.21a8.981,8.981,0,0,0-8.95-8.946" transform="translate(0)" fill="#fff" fill-rule="evenodd"/>
                <path id="Caminho_1628" data-name="Caminho 1628" d="M929.786,331.425a12.621,12.621,0,0,1-4.274-.749,11.316,11.316,0,0,1-3.812-2.3l2.477-2.968a12.45,12.45,0,0,0,2.682,1.688,7.168,7.168,0,0,0,3.008.627,3.664,3.664,0,0,0,2.029-.477,1.5,1.5,0,0,0,.721-1.321v-.055a1.658,1.658,0,0,0-.149-.722,1.516,1.516,0,0,0-.572-.585,6.239,6.239,0,0,0-1.175-.545c-.5-.181-1.152-.372-1.951-.571a23.9,23.9,0,0,1-2.623-.79,7.42,7.42,0,0,1-1.992-1.075,4.378,4.378,0,0,1-1.27-1.592,5.546,5.546,0,0,1-.449-2.37v-.054a5.553,5.553,0,0,1,.486-2.355,5.382,5.382,0,0,1,1.365-1.81,6.14,6.14,0,0,1,2.1-1.171,8.422,8.422,0,0,1,2.69-.409,11.4,11.4,0,0,1,3.853.626,11.2,11.2,0,0,1,3.226,1.8l-2.178,3.159a13.812,13.812,0,0,0-2.5-1.375,6.394,6.394,0,0,0-2.451-.5,2.967,2.967,0,0,0-1.838.476,1.448,1.448,0,0,0-.612,1.184v.055a1.73,1.73,0,0,0,.177.8,1.606,1.606,0,0,0,.64.612,6.209,6.209,0,0,0,1.271.518q.809.245,2.033.572a19.333,19.333,0,0,1,2.582.857,7.225,7.225,0,0,1,1.91,1.144,4.367,4.367,0,0,1,1.174,1.565,5.322,5.322,0,0,1,.4,2.151v.055a5.929,5.929,0,0,1-.513,2.518,5.2,5.2,0,0,1-1.434,1.851,6.476,6.476,0,0,1-2.2,1.144,9.551,9.551,0,0,1-2.828.394m-26.49-19.33h8.712a7.518,7.518,0,0,1,5.554,1.933,5.889,5.889,0,0,1,1.634,4.357v.054a5.969,5.969,0,0,1-1.13,3.77,6.544,6.544,0,0,1-2.954,2.138l4.656,6.806h-4.9l-4.084-6.1h-3.295v6.1H903.3Zm8.439,9.257a3.481,3.481,0,0,0,2.383-.735,2.49,2.49,0,0,0,.831-1.96V318.6a2.432,2.432,0,0,0-.871-2.042,3.847,3.847,0,0,0-2.423-.681h-4.166v5.473Z" transform="translate(-467.443 -161.364)" fill="#fff"/>
            </g>
        </svg>
    )
}

export default LogoTeste;