import React from 'react';
import * as S from './styled';
import Acesso from '../../assets/imagens/acess.png';
import LogoAzul from '../../assets/imagens/logo_bancariosrs_azul.png';
import icone1 from '../../assets/imagens/icone1.png';
import icone2 from '../../assets/imagens/icone2.png';
import icone3 from '../../assets/imagens/icone3.png';
import icone4 from '../../assets/imagens/icone4.png';
// import Copy from '../../assets/imagens/copy.png';
import AlertWhite from '../../assets/imagens/icoalert.png';
import { Container, Row, Col } from 'reactstrap';
import jsPDF from "jspdf";
import html2canvas from 'html2canvas';
import LogoTeste2 from '../../assets/svg/LogoTeste2';

const Pagina7 = ({senha, protocolo, email}) => {

    // const copyToClipboard = async () => {
    //     try {
    //       await navigator.clipboard.writeText(`Seu login é o seu protocolo \n${protocolo} \n\nSua senha de acesso é\n${senha}`);
    //     } catch (err) {
    //     }
    // };

    // const copyToClipboardEmail = async () => {
    //     try {
    //       await navigator.clipboard.writeText(`Seu login é o seu e-mail \n${email} \n\nSua senha de acesso é\n${senha}`);
    //     } catch (err) {
    //     }
    // };

    const handlePDF = () => {
        const elem = document.getElementById("basic-table");

        html2canvas(elem).then(function(canvas) {
            let imgData = canvas.toDataURL('image/png');
            let pdf = new jsPDF('landscape'); 
            pdf.addImage(imgData, 'PNG', 10, 10);
            pdf.save(`protocolo${protocolo}.pdf`);
          })
    }

    return (
        <S.Base>
            <S.Conteudo id="basic-table" className="basic-table">
                <Container>
                    <Row>
                        <Col md={12} style={{ textAlign: "center" }}>
                            <img src={LogoAzul} alt="Icone BancariosRS" />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12} style={{ textAlign: "center", marginTop: "50px" }}>
                            <LogoTeste2 width="230" height="80" />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12} style={{ textAlign: "center", marginTop: "50px" }}>
                            <S.DivGrid>
                                <S.TextBody>Sua denúncia foi enviada com sucesso.</S.TextBody>
                                <S.TextLight>Para acompanhar sua denúncia acesse:</S.TextLight>
                                <S.DivFlex>
                                    <S.TextBody>minhadenuncia.bancariors.com.br</S.TextBody>
                                    <S.CustomLink href="http://minhadenuncia.bancariosrs.com.br">
                                        <img src={Acesso} alt="Icone canal de denúncia" />
                                        <S.TextLightPlus>Acessar</S.TextLightPlus>
                                    </S.CustomLink>
                                </S.DivFlex>
                            </S.DivGrid>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12} style={{ textAlign: "center", marginTop: "30px" }}>
                            <S.DivGridAlert>
                                <S.DivFlex>
                                    <img src={AlertWhite} alt="Icone de Alerta" height={"100px"} style={{ marginTop: "20px", marginLeft: "20px" }} />
                                    <S.DivAcesso>
                                        {email ?
                                            <>
                                                <S.TextLightwhite>Seu login é o seu e-mail</S.TextLightwhite>
                                                <S.TextBodyWhite>{email}</S.TextBodyWhite>
                                            </>
                                        :
                                            <>
                                                <S.TextLightwhite>Seu login é o seu protocolo</S.TextLightwhite>
                                                <S.TextBodyWhite>{protocolo}</S.TextBodyWhite>
                                            </>
                                        }
                                        <S.TextLightwhite>Sua senha de acesso é</S.TextLightwhite>
                                        <S.TextBodyWhite>{senha}</S.TextBodyWhite>
                                    </S.DivAcesso>
                                </S.DivFlex>
                                <S.DivButtonClick onClick={() => handlePDF()} data-html2canvas-ignore="true">
                                    Salvar dados de acesso
                                </S.DivButtonClick>
                            </S.DivGridAlert>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12} style={{ textAlign: "center", marginTop: "30px" }}>
                            <S.DivFlex>
                                <img src={icone1} alt="Icone canal de denúncia" style={{ objectFit: "contain" }} />
                                <img src={icone2} alt="Icone canal de denúncia" style={{ objectFit: "contain" }} />
                                <img src={icone3} alt="Icone canal de denúncia" style={{ objectFit: "contain" }} />
                                <img src={icone4} alt="Icone canal de denúncia" style={{ objectFit: "contain" }} />
                            </S.DivFlex>
                        </Col>
                    </Row>
                </Container>
            </S.Conteudo>
        </S.Base>
    );
};

export default Pagina7;